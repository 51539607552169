import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useData from '../../data';

export default function useAdjustForInflation() {
  const adjustForInflation = useSelector((state) => state.adjustForInflation);
  const { inflationFactors } = useData();
  return useCallback(
    (value, valueYear) => {
      if (!adjustForInflation) {
        return value;
      }

      const inflationFactor = inflationFactors.get(valueYear);
      if (!inflationFactor) {
        return value;
      }

      return value / inflationFactor;
    },
    [adjustForInflation, inflationFactors],
  );
}

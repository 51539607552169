import React from 'react';
import PropTypes from 'prop-types';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import rootReducer from './src/features';
import Suspense from './src/components/Suspense';
import PageWrapper from './src/components/PageWrapper';
import Loading from './src/components/Loading';
import { MDXPageContextProvider } from './src/components/MDXPage';

const store = configureStore({
  reducer: rootReducer,
});

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};

export const wrapPageElement = ({ element }) => (
  <Suspense fallback={<Loading />}>
    <MDXPageContextProvider>
      <PageWrapper>{element}</PageWrapper>
    </MDXPageContextProvider>
    <Loading loaded />
  </Suspense>
);

wrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
};

export const shouldUpdateScroll = ({ routerProps }) => {
  return routerProps.location.hash ? false : 'top';
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import c from 'classnames';

import { setLayerGroupVisible, setArcsVisible } from '../../features/mapboxMap';
import ToggleSlider from '../ToggleSlider';
import styles from './index.module.css';

export default function LayerControls() {
  const { layerGroupVisible, arcsVisible } = useSelector(
    (state) => state.mapboxMap,
  );

  const [transfersVisible, statesVisible, satelliteHidden] = useMemo(
    () => [
      layerGroupVisible.transfers,
      layerGroupVisible.states,
      !layerGroupVisible.satellite,
    ],
    [layerGroupVisible],
  );

  const dispatch = useDispatch();
  const toggleArcsVisible = useCallback(
    () => dispatch(setArcsVisible(!arcsVisible)),
    [arcsVisible, dispatch],
  );

  const toggleTransfersVisible = useCallback(
    () =>
      dispatch(
        setLayerGroupVisible({
          transfers: !transfersVisible,
        }),
      ),
    [dispatch, transfersVisible],
  );

  const toggleStatesVisible = useCallback(
    () => dispatch(setLayerGroupVisible({ states: !statesVisible })),
    [dispatch, statesVisible],
  );

  const setSatelliteHidden = useCallback(
    (hidden) => dispatch(setLayerGroupVisible({ satellite: !hidden })),
    [dispatch],
  );

  return (
    <div className={styles.LayerControls}>
      <div className={styles.colorScale}>
        <span>Land</span>
        <span>University</span>
      </div>
      <div className={styles.mapOptions}>Map Options</div>
      <div className={styles.layerOptions}>
        <div
          onClick={toggleArcsVisible}
          className={c(styles.checkbox, arcsVisible && styles.checked)}
        >
          Land-University Links
        </div>
        <div
          onClick={toggleTransfersVisible}
          className={c(styles.checkbox, transfersVisible && styles.checked)}
        >
          Indigenous Cessions
        </div>
        <div
          onClick={toggleStatesVisible}
          className={c(styles.checkbox, statesVisible && styles.checked)}
        >
          Current State Boundaries
        </div>
        <ToggleSlider
          value={satelliteHidden}
          onChange={setSatelliteHidden}
          falseLabel="Satellite Map"
          trueLabel="Solid Color"
          inverted
        />
      </div>
    </div>
  );
}

import MDXPage from "../../../../src/components/MDXPage";
import PageMeta from "../../../../src/components/PageMeta";
import Section from "../../../../src/components/Section";
import FloatingNavbar from "../../../../src/components/FloatingNavbar";
import MapboxMap from "../../../../src/components/MapboxMap";
import FilterPhrase from "../../../../src/components/FilterPhrase";
import StateDropdown from "../../../../src/components/StateDropdown";
import Table from "../../../../src/components/Table";
import titleHTML from "../../../../content/overview/title.html";
import introductionHTML from "../../../../content/overview/introduction.html";
import universitiesHTML from "../../../../content/overview/universities.html";
import tribesHTML from "../../../../content/overview/tribes.html";
import landsHTML from "../../../../content/overview/lands.html";
import styles from "../../../../src/templates/Overview.module.css";
import * as React from 'react';
export default {
  MDXPage,
  PageMeta,
  Section,
  FloatingNavbar,
  MapboxMap,
  FilterPhrase,
  StateDropdown,
  Table,
  titleHTML,
  introductionHTML,
  universitiesHTML,
  tribesHTML,
  landsHTML,
  styles,
  React
};
import React from 'react';
import { shape, node, string, number, arrayOf } from 'prop-types';

import Link from '../Link';
import {
  Decimal,
  Currency,
  CurrencyWithCents,
  Multiple,
  DateString,
} from '../Formatted';
import styles from './index.module.css';

export const LinkCell = ({
  cell: {
    value,
    row: {
      original: { href },
    },
  },
}) => {
  return (
    <Link href={href}>
      <strong>{value}</strong>
    </Link>
  );
};

LinkCell.propTypes = {
  cell: shape({
    value: node.isRequired,
    row: shape({ original: shape({ href: string.isRequired }).isRequired })
      .isRequired,
  }).isRequired,
};

export const MultiLinkCell = ({ cell: { value } }) => {
  return value.map(({ href, name }, i) => (
    <span key={href}>
      <Link href={href}>
        <strong>{name}</strong>
      </Link>
      {i < value.length - 1 ? ', ' : null}
    </span>
  ));
};

MultiLinkCell.propTypes = {
  cell: shape({
    value: arrayOf(shape({ href: string.isRequired, name: string.isRequired }))
      .isRequired,
  }).isRequired,
};

export const DecimalCell = ({ cell: { value } }) => <Decimal>{value}</Decimal>;
DecimalCell.propTypes = { cell: shape({ value: number }).isRequired };

export const CurrencyCell = ({ cell: { value } }) => (
  <Currency>{value}</Currency>
);

CurrencyCell.propTypes = {
  cell: shape({ value: number }).isRequired,
};

export const CurrencyWithCentsCell = ({ cell: { value } }) => (
  <CurrencyWithCents>{value}</CurrencyWithCents>
);

CurrencyWithCentsCell.propTypes = {
  cell: shape({ value: number }).isRequired,
};

export const MultipleCell = ({ cell: { value } }) => (
  <span className={styles.multiple}>
    <Multiple suffix="x">{value}</Multiple>
  </span>
);

MultipleCell.propTypes = { cell: shape({ value: number }).isRequired };

export const DateCell = ({ cell: { value } }) => (
  <DateString>{value}</DateString>
);

DateCell.propTypes = { cell: shape({ value: string }).isRequired };

import { createPortal } from 'react-dom';
import { useRef, useEffect } from 'react';

import { useMDXPageContext } from '../../MDXPage';

export default function Popup({
  mouseLngLatRef,
  popupFeature,
  clicked,
  onClose,
  children,
}) {
  const popupElementRef = useRef(document.createElement('div'));
  const popupRef = useRef();
  const { mapRef } = useMDXPageContext();

  useEffect(() => {
    if (mapRef.current) {
      popupRef.current = new window.mapboxgl.Popup({
        offset: 10,
        maxWidth: 'none',
        closeOnMove: true,
        closeButton: clicked,
      })
        .setDOMContent(popupElementRef.current)
        .on('close', onClose);
    }
  }, [clicked, mapRef, onClose]);

  useEffect(() => {
    if (popupFeature && popupFeature.geometry.type === 'Point') {
      popupRef.current.setLngLat(popupFeature.geometry.coordinates);
    } else {
      popupRef.current.setLngLat(mouseLngLatRef.current);
    }

    popupRef.current.addTo(mapRef.current);
    return () => popupRef.current.remove();
  }, [mapRef, mouseLngLatRef, popupFeature]);

  return createPortal(children, popupElementRef.current);
}

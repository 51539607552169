/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import { string, bool } from 'prop-types';

import ResetButton from './ResetButton';

const FilterPhraseComponents = Object.assign(
  {},
  ...[
    'parcels-given-to-universities-in-state',
    'parcels-in-map-view-area-grant-states',
    'parcels-in-state-ceded-by-tribe',
    'parcels-in-state',
    'tribe-signatory-to-transfers-for-parcels',
    'tribes-ceded-land-in-state',
    'universities-earned-from-tribe-parcels-in-state',
    'universities-in-state-raised',
    'university-earned-from-parcels-in-state',
    'university-received-parcels-in-state',
  ].map((name) => ({ [name]: require(`./phrase-${name}`).default })),
);

export default function FilterPhrase({ name, resetButton, ...rest }) {
  const FilterPhraseComponent = FilterPhraseComponents[name];
  return (
    <>
      {resetButton ? <ResetButton /> : null}
      <FilterPhraseComponent {...rest} />
    </>
  );
}

FilterPhrase.propTypes = {
  name: string.isRequired,
  resetButton: bool,
};

FilterPhrase.defaultProps = {
  resetButton: true,
};

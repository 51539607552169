import React, { useState, useEffect } from 'react';
import { bool } from 'prop-types';
import c from 'classnames';

import { useSuspended } from './Suspense';
import styles from './Loading.module.css';

export default function Loading({ loaded }) {
  const suspended = useSuspended();
  const [overlayVisible, setOverlayVisible] = useState(true);
  const [textVisible, setTextVisible] = useState(!!suspended);

  useEffect(() => {
    setTextVisible(true);
    setOverlayVisible(!loaded || suspended);
  }, [loaded, suspended]);

  return (
    <div
      className={c(styles.Loading, {
        [styles.overlayVisible]: overlayVisible,
      })}
    >
      <h1 className={c(styles.text, { [styles.textVisible]: textVisible })}>
        Loading...
      </h1>
    </div>
  );
}

Loading.propTypes = {
  loaded: bool,
};

Loading.defaultProps = {
  loaded: false,
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import Link from './Link';

import styles from './ToggleSlider.module.css';

export default function ToggleSlider({
  falseLabel,
  trueLabel,
  value,
  onChange,
  inverted,
}) {
  const handleChange = (event, changeValue) => {
    event.preventDefault();
    onChange(changeValue);
  };

  return (
    <span className={styles.ToggleSlider}>
      <Link
        onClick={(e) => handleChange(e, false)}
        className={c(!value && styles.active)}
      >
        {falseLabel}
      </Link>
      <div
        onClick={(e) => handleChange(e, !value)}
        className={c(
          styles.toggle,
          value && styles.active,
          inverted && styles.inverted,
        )}
      />
      <Link
        onClick={(e) => handleChange(e, true)}
        className={c(value && styles.active)}
      >
        {trueLabel}
      </Link>
    </span>
  );
}

ToggleSlider.propTypes = {
  falseLabel: PropTypes.node.isRequired,
  trueLabel: PropTypes.node.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  inverted: PropTypes.bool,
};

ToggleSlider.defaultProps = {
  value: false,
  inverted: false,
};

import React, { useMemo, useCallback } from 'react';
import { number, bool, string, arrayOf, shape, objectOf } from 'prop-types';

import Link from '../Link';
import useData, { provideData } from '../../data';
import BaseTable from './BaseTable';
import {
  LinkCell,
  DecimalCell,
  CurrencyWithCentsCell,
  MultipleCell,
} from './Cell';

import styles from './index.module.css';
import useAdjustForInflation from '../../features/adjustForInflation/useAdjustForInflation';

function useTableData({
  states: statesArr,
  grantStates: grantStatesArr,
  tribe,
  university,
  parcels,
}) {
  const {
    stateAllFieldsById,
    tribesByParcel,
    parcelSlugById,
    parcelStateById,
    parcelGrantStateById,
    parcelCountyById,
    parcelAreaById,
    paidToTribesByParcel,
    raisedByUniversitiesByParcel,
    universityAllFieldsById,
    parcelPriceSoldById,
  } = useData();

  const states = useMemo(() => statesArr && new Set(statesArr), [statesArr]);
  const grantStates = useMemo(() => grantStatesArr && new Set(grantStatesArr), [
    grantStatesArr,
  ]);

  const {
    state: universityState,
    grantShare: universityGrantShare,
    grantReceivedYear: universityGrantReceivedYear,
  } = useMemo(() => (university && universityAllFieldsById[university]) || {}, [
    university,
    universityAllFieldsById,
  ]);

  const adjustForInflation = useAdjustForInflation(); // dep: inflationFactors
  const getRaisedByUniversitiesForParcel = useCallback(
    university
      ? (parcel) =>
          adjustForInflation(
            parcelPriceSoldById[parcel] * universityGrantShare,
            universityGrantReceivedYear,
          )
      : (parcel) => raisedByUniversitiesByParcel[parcel],
    [
      adjustForInflation,
      parcelPriceSoldById,
      universityGrantReceivedYear,
      universityGrantShare,
    ],
  );

  return useMemo(
    () =>
      (parcels || Object.keys(parcelSlugById))
        .map((parcel) => {
          const state = parcelStateById[parcel];
          const grantState = parcelGrantStateById[parcel];
          const tribes = tribesByParcel[parcel] || [];
          if (
            (states && !states.has(state)) ||
            (grantStates && !grantStates.has(grantState)) ||
            (tribe && tribes.indexOf(tribe) === -1) ||
            (universityState && universityState !== grantState)
          ) {
            return null;
          }

          const slug = parcelSlugById[parcel];
          const county = parcelCountyById[parcel];
          const {
            name: stateName,
            abbreviation: stateAbbreviation,
          } = stateAllFieldsById[state];

          const { abbreviation: grantStateAbbreviation } =
            (grantState && stateAllFieldsById[grantState]) || {};

          const area = parcelAreaById[parcel];
          const paidToTribes =
            paidToTribesByParcel[parcel] * (universityGrantShare || 1);

          const raisedByUniversities = getRaisedByUniversitiesForParcel(parcel);
          return {
            href: `/lands/${slug}`,
            county: `${county}, ${stateAbbreviation}`,
            state: stateName,
            grantState: grantStateAbbreviation,
            tribes: tribes.length,
            acres: area,
            payment: paidToTribes,
            uniEarned: raisedByUniversities,
            multiple: raisedByUniversities / paidToTribes,
          };
        })
        .filter((a) => a),
    [
      getRaisedByUniversitiesForParcel,
      grantStates,
      paidToTribesByParcel,
      parcelAreaById,
      parcelCountyById,
      parcelGrantStateById,
      parcelSlugById,
      parcelStateById,
      parcels,
      stateAllFieldsById,
      states,
      tribe,
      tribesByParcel,
      universityGrantShare,
      universityState,
    ],
  );
}

const columns = [
  {
    Header: '',
    id: 'i',
    disableSortBy: true,
  },
  {
    Header: 'County',
    accessor: 'county',
    Cell: LinkCell,
  },
  {
    Header: 'State',
    accessor: 'state',
  },
  {
    Header: 'Grant State',
    accessor: 'grantState',
  },
  {
    Header: 'Tribal Nations',
    accessor: 'tribes',
    hideOnMobile: true,
    Cell: DecimalCell,
  },
  {
    Header: 'Acres',
    accessor: 'acres',
    hideOnMobile: true,
    Cell: DecimalCell,
  },
  {
    Header: 'U.S. Paid',
    accessor: 'payment',
    hideOnMobile: true,
    Cell: CurrencyWithCentsCell,
  },
  {
    Header: 'Univ. Raised',
    accessor: 'uniEarned',
    Cell: CurrencyWithCentsCell,
  },
  {
    Header: 'Return',
    accessor: 'multiple',
    hideOnMobile: true,
    Cell: MultipleCell,
  },
];

const goToPageButton = (
  <Link className={styles.moreButton} href="/lands">
    Go to Lands page
  </Link>
);

function LandsTable({
  dataParams,
  title,
  initialSortBy,
  pageSize,
  isOverview,
}) {
  const data = useTableData(dataParams);
  return (
    <BaseTable
      title={title}
      searchBy={['county', 'state']}
      searchPlaceholder="Search land parcels by location"
      initialSortBy={initialSortBy}
      columns={columns}
      hiddenColumns={['state']}
      pageSize={pageSize}
      moreButton={isOverview ? goToPageButton : null}
      data={data}
    />
  );
}

LandsTable.propTypes = {
  dataParams: shape({
    statesArr: arrayOf(string),
    grantStatesArr: arrayOf(string),
    tribe: string,
    university: string,
    parcels: arrayOf(string),
  }),
  title: string,
  initialSortBy: objectOf(shape({ desc: bool.isRequired })),
  pageSize: number.isRequired,
  isOverview: bool,
};

LandsTable.defaultProps = {
  dataParams: {},
  title: 'Land grant parcels benefiting university ',
  initialSortBy: { uniEarned: { desc: true } },
  isOverview: false,
};

export default provideData(LandsTable, [
  'stateAllFieldsById',
  'tribesByParcel',
  'parcelSlugById',
  'parcelStateById',
  'parcelGrantStateById',
  'parcelCountyById',
  'parcelAreaById',
  'paidToTribesByParcel',
  'raisedByUniversitiesByParcel',
  'universityAllFieldsById',
  'inflationFactors',
  'parcelPriceSoldById',
]);

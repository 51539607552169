import React from 'react';
import { shape, bool } from 'prop-types';
import c from 'classnames';

import useData, { provideData } from '../../../data';
import useMapboxConfig from '../useMapboxConfig';
import Link from '../../Link';
import { ITEM_TYPES } from '../../../constants';
import { Percent, Decimal, Currency, Multiple } from '../../Formatted';
import styles from './index.module.css';

function UniversityPopupContent({ popupFeature, clicked }) {
  const { keysByLayer } = useMapboxConfig();
  const {
    universitiesByName,
    universityAllFieldsById,
    parcelAreaByGrantState,
    raisedByUniversitiesByUniversity,
    paidToTribesByUniversity,
  } = useData();

  const { mapboxFeatureProperty: featureProperty } = keysByLayer[
    popupFeature.layer.id
  ];

  const name = popupFeature.properties[featureProperty];
  const university = universitiesByName[name];
  const { slug, city, stateAbbreviation, grantShare } = universityAllFieldsById[
    university
  ];

  const areaReceived =
    parcelAreaByGrantState[universityAllFieldsById[university].state];

  const raisedByUniversity = raisedByUniversitiesByUniversity[university];
  const paidToTribes = paidToTribesByUniversity[university];
  const tableRows = [
    ['University', name],
    ['Location', `${city}, ${stateAbbreviation}`],
    ['Endow. Share', <Percent>{grantShare}</Percent>],
    ['Grant Acres', <Decimal>{areaReceived}</Decimal>],
    ['Univ. Raised', <Currency>{raisedByUniversity}</Currency>],
    [
      'Return',
      <span className={styles.multiple}>
        <Multiple suffix="x">{raisedByUniversity / paidToTribes}</Multiple>
      </span>,
    ],
  ];

  const universityUrl = `/${ITEM_TYPES.university.slug}/${slug}`;

  return (
    <div className={styles.MapPopup}>
      <table>
        <tbody>
          {tableRows.map(([label, value]) => (
            <tr key={label}>
              <td className={styles.headerColumn}>{label}</td>
              <td className={styles.bodyColumn}>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {clicked ? (
        <Link
          href={universityUrl}
          className={c('btn btn-link', styles.moreButton)}
        >
          See University Page
        </Link>
      ) : null}
    </div>
  );
}

UniversityPopupContent.propTypes = {
  popupFeature: shape({ properties: shape({}).isRequired }).isRequired,
  clicked: bool,
};

UniversityPopupContent.defaultProps = {
  clicked: false,
};

export default provideData(UniversityPopupContent, [
  'universitiesByName',
  'universityAllFieldsById',
  'parcelAreaByGrantState',
  'raisedByUniversitiesByUniversity',
  'paidToTribesByUniversity',
]);

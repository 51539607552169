import React, { createContext, useContext, useMemo, lazy } from 'react';
import PropTypes from 'prop-types';

import Loading from '../components/Loading';
import Suspense from '../components/Suspense';

const DataContext = createContext({});
DataContext.displayName = 'DataContext';

/*
 * Hook giving component access to data provided by all ancestor providers.
 */

export default function useData() {
  return useContext(DataContext);
}

/*
 * Returns a component wrapped in 1+ provider components to provide
 * the requested data asynchronously.  Requires a Suspense component
 * as an ancestor.
 */

export function provideData(Component, srcs) {
  const LazyComponents = Object.assign(
    {},
    ...srcs.map((src) => ({ [src]: lazy(() => import(`./${src}`)) })),
  );

  const DataDependencyProvider = (props) => {
    const ancestorData = useData();
    if (typeof window === 'undefined') {
      return <Loading />;
    }

    return (
      <Suspense fallback={<Loading />}>
        {srcs
          .filter((src) => !ancestorData[src])
          .reduce((element, src) => {
            const DataProvider = LazyComponents[src];
            return <DataProvider>{element}</DataProvider>;
          }, <Component {...props} />)}
      </Suspense>
    );
  };

  DataDependencyProvider.displayName = `DataDependencyProvider(${srcs.join(
    ', ',
  )})`;

  return DataDependencyProvider;
}

/*
 * Returns a provider component for use as the default export of a
 * data module.
 */

export function createDataProvider(useProviderData, srcs, name) {
  const DataProvider = ({ children }) => {
    const ancestorData = useData();
    const providerData = useProviderData(ancestorData);
    const contextValue = useMemo(() => ({ ...ancestorData, ...providerData }), [
      ancestorData,
      providerData,
    ]);

    return (
      <DataContext.Provider value={contextValue}>
        {children}
      </DataContext.Provider>
    );
  };

  DataProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  DataProvider.displayName = `DataProvider([${srcs.join(', ')}] => ${name})`;
  return provideData(DataProvider, srcs);
}

import React, { useState, useCallback, useEffect } from 'react';
import {
  string,
  bool,
  arrayOf,
  shape,
  func,
  number,
  node,
  elementType,
  oneOfType,
  object,
  objectOf,
} from 'prop-types';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import { FontAwesomeIcon as F } from '@fortawesome/react-fontawesome';
import { faSearch as search } from '@fortawesome/free-solid-svg-icons';
import c from 'classnames';

import Search from './Search';
import styles from './index.module.css';

export default function Table({
  title,
  searchBy,
  searchPlaceholder,
  columns,
  initialSortBy,
  hiddenColumns,
  pageSize,
  moreButton,
  data,
  className,
}) {
  const [searchVisible, setSearchVisible] = useState();
  const matchSorterFilter = useCallback(
    (rows, ids, filterValue) => {
      return matchSorter(rows, filterValue, {
        keys: searchBy.map((key) => (row) => row.values[key]),
        threshold: matchSorter.rankings.CONTAINS,
      });
    },
    [searchBy],
  );

  matchSorterFilter.autoRemove = (val) => !val;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      globalFilter: matchSorterFilter,
      autoResetSortBy: false,
      disableSortRemove: true,
      initialState: {
        hiddenColumns,
        sortBy:
          initialSortBy &&
          Object.entries(initialSortBy).map(([id, { desc }]) => ({ id, desc })),
      },
    },
    useGlobalFilter,
    useSortBy,
  );

  useEffect(() => {
    if (!searchVisible) {
      setGlobalFilter(undefined);
    }
  }, [searchVisible, setGlobalFilter]);

  const [resultsVisible, setResultsVisible] = useState(
    Math.min(rows.length, pageSize),
  );

  useEffect(() => {
    setResultsVisible(Math.min(rows.length, pageSize));
  }, [pageSize, rows.length]);

  const loadMore = useCallback(() => {
    setResultsVisible(Math.min(rows.length, resultsVisible + pageSize));
  }, [pageSize, resultsVisible, rows.length]);

  const visibleRows = rows.slice(0, resultsVisible);

  return (
    <div className={c(styles.container, className)}>
      <div className={styles.toolbar}>
        <div className={styles.title}>{title}</div>
        {searchBy ? (
          <button
            type="button"
            className={c('btn btn-link', styles.searchToggle)}
            onClick={() => setSearchVisible(!searchVisible)}
          >
            <F icon={search} className={styles.searchIcon} />
            <span className="d-none d-desktop-inline-block ml-2">Search</span>
          </button>
        ) : null}
      </div>
      {searchVisible ? (
        <Search
          searchPlaceholder={searchPlaceholder}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      ) : null}
      <table className={styles.table} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    column.getSortByToggleProps(),
                    {
                      className: c({
                        'd-none d-desktop-table-cell': column.hideOnMobile,
                        [styles.descending]:
                          column.isSorted && column.isSortedDesc,
                        [styles.ascending]:
                          column.isSorted && !column.isSortedDesc,
                      }),
                    },
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {visibleRows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.hideOnMobile
                            ? 'd-none d-desktop-table-cell'
                            : null,
                        },
                      ])}
                    >
                      {cell.column.id === 'i'
                        ? rowIndex + 1
                        : cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.showing}>
        {rows.length
          ? `Showing 1 - ${resultsVisible} of ${rows.length}`
          : '0 rows'}
      </div>
      {moreButton || (
        <button
          type="button"
          className={c('btn btn-link', styles.moreButton)}
          disabled={resultsVisible === rows.length}
          onClick={loadMore}
        >
          Load More
        </button>
      )}
    </div>
  );
}

Table.propTypes = {
  title: string.isRequired,
  searchBy: arrayOf(string),
  searchPlaceholder: string,
  columns: arrayOf(
    shape({
      Header: node.isRequired,
      accessor: oneOfType([string, func]),
      id: string,
      Cell: elementType,
      hideOnMobile: bool,
    }),
  ).isRequired,
  hiddenColumns: arrayOf(string),
  initialSortBy: objectOf(shape({ desc: bool.isRequired })),
  pageSize: number.isRequired,
  moreButton: node,
  CardViewComponent: elementType,
  cardViewDefault: bool,
  data: arrayOf(oneOfType([object, string])).isRequired,
  className: string,
};

Table.defaultProps = {
  searchBy: null,
  searchPlaceholder: 'Search',
  hiddenColumns: [],
  initialSortBy: null,
  moreButton: null,
  CardViewComponent: null,
  cardViewDefault: false,
  className: '',
};

import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useRef,
} from 'react';
import { node } from 'prop-types';

const MDXPageContext = createContext();

export function MDXPageContextProvider({ children }) {
  const mapRef = useRef();
  const [page, setPage] = useState(null);
  const contextValue = useMemo(
    () => ({
      mapRef,
      page,
      setPage,
    }),
    [page],
  );

  return (
    <MDXPageContext.Provider value={contextValue}>
      {children}
    </MDXPageContext.Provider>
  );
}

MDXPageContextProvider.propTypes = { children: node.isRequired };

export default function useMDXPageContext() {
  return useContext(MDXPageContext);
}

import React from 'react';

import LandsTable from './LandsTable';
import { useRenderedParcels } from '../MapboxMap';
import { provideData } from '../../data';

function LandsForMapViewTable(props) {
  const parcels = useRenderedParcels(); // dep: parcelsByMtrsaId
  return (
    <LandsTable
      dataParams={{ parcels }}
      initialSortBy={{ grantState: { desc: false } }}
      {...props}
    />
  );
}

export default provideData(LandsForMapViewTable, ['parcelsByMtrsaId']);

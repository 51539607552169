import React, { useMemo } from 'react';

import useData, { provideData } from '../../data';
import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import StateDropdown from '../StateDropdown';

import styles from './index.module.css';
import { Decimal } from '../Formatted';

function FilterPhrase() {
  const { parcelsByGrantState } = useData();
  const selectedStates = useSelectedStates();
  const nParcels = useMemo(
    () =>
      selectedStates
        .map((state) => parcelsByGrantState[state])
        .filter((a) => a)
        .flat().length,
    [parcelsByGrantState, selectedStates],
  );

  return (
    <div className={styles.FilterPhrase}>
      <strong>
        <Decimal>{nParcels}</Decimal>
      </strong>
      <span>{` parcel${nParcels === 1 ? '' : 's'} of Indigenous land `}</span>
      <span>benefited universities in </span>
      <StateDropdown />
    </div>
  );
}

export default provideData(FilterPhrase, [
  'stateAllFieldsById',
  'statesByGrantType',
  'parcelsByGrantState',
]);

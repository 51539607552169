export const ITEM_TYPES = {
  university: {
    slug: 'universities',
  },

  tribe: {
    slug: 'tribes',
  },

  parcel: {
    slug: 'lands',
  },
};

export const ROUTES = {
  overview: {
    path: '/',
    template: './templates/Overview.mdx',
  },

  universityArchive: {
    path: '/universities',
    template: './templates/UniversityArchive.mdx',
  },

  universitySingle: {
    path: '/universities/*',
    template: './templates/UniversitySingle.mdx',
  },

  tribeArchive: {
    path: '/tribes',
    template: './templates/TribeArchive.mdx',
  },

  tribeSingle: {
    path: '/tribes/*',
    template: './templates/TribeSingle.mdx',
  },

  landArchive: {
    path: '/lands',
    template: './templates/LandArchive.mdx',
  },

  landSingle: {
    path: '/lands/*',
    template: './templates/LandSingle.mdx',
  },

  notFound: {
    path: '/*',
    template: './templates/404.mdx',
  },
};

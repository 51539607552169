// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-overview-mdx": () => import("./../src/templates/Overview.mdx" /* webpackChunkName: "component---src-templates-overview-mdx" */),
  "component---src-templates-university-archive-mdx": () => import("./../src/templates/UniversityArchive.mdx" /* webpackChunkName: "component---src-templates-university-archive-mdx" */),
  "component---src-templates-university-single-mdx": () => import("./../src/templates/UniversitySingle.mdx" /* webpackChunkName: "component---src-templates-university-single-mdx" */),
  "component---src-templates-tribe-archive-mdx": () => import("./../src/templates/TribeArchive.mdx" /* webpackChunkName: "component---src-templates-tribe-archive-mdx" */),
  "component---src-templates-tribe-single-mdx": () => import("./../src/templates/TribeSingle.mdx" /* webpackChunkName: "component---src-templates-tribe-single-mdx" */),
  "component---src-templates-land-archive-mdx": () => import("./../src/templates/LandArchive.mdx" /* webpackChunkName: "component---src-templates-land-archive-mdx" */),
  "component---src-templates-land-single-mdx": () => import("./../src/templates/LandSingle.mdx" /* webpackChunkName: "component---src-templates-land-single-mdx" */),
  "component---src-templates-404-mdx": () => import("./../src/templates/404.mdx" /* webpackChunkName: "component---src-templates-404-mdx" */)
}


import { useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useData from '../../data';
import { useMDXPageContext } from '../MDXPage';
import useMapboxConfig from './useMapboxConfig';
import { ITEM_TYPES } from '../../constants';

export const OVERLAY_SOURCE_ID = 'canvas-source';
export const OVERLAY_LAYER_ID = 'canvas-layer';

export default function useParcelOverlay() {
  const canvasRef = useRef();
  const { allLayerGroups } = useMapboxConfig();
  const { bounds } = useSelector((state) => state.mapboxMap);
  const { parcelsByMtrsaId } = useData();
  const {
    page: { itemType, singleId },
    mapRef,
  } = useMDXPageContext();

  const parcelId = itemType === ITEM_TYPES.parcel && singleId;
  const [canvasCtx, canvasWidth, canvasHeight] = useMemo(() => {
    if (mapRef.current) {
      if (!canvasRef.current) {
        canvasRef.current = document.createElement('canvas');
      }

      const { x: width, y: height } = mapRef.current.project([
        bounds[1][0],
        bounds[0][1],
      ]);

      canvasRef.current.width = width * 3;
      canvasRef.current.height = height * 3;
      const ctx = canvasRef.current.getContext('2d');
      ctx.globalCompositeOperation = 'source-over';
      ctx.clearRect(0, 0, width * 3, height * 3);
      ctx.fillStyle = 'rgba(0, 0, 0, 0.2)';
      ctx.fillRect(0, 0, width * 3, height * 3);

      return [ctx, width, height];
    }

    return [];
  }, [bounds, mapRef]);

  useMemo(() => {
    if (canvasCtx && parcelId) {
      const { layers, key } = allLayerGroups.parcelPolygons;
      const [parcelFeature] = mapRef.current.queryRenderedFeatures({
        layers,
        filter: [
          '==',
          ['get', key.mapboxFeatureProperty],
          parcelsByMtrsaId[parcelId],
        ],
      });

      if (parcelFeature) {
        canvasCtx.globalCompositeOperation = 'destination-out';
        canvasCtx.fillStyle = 'rgba(255, 255, 255, 1)';
        canvasCtx.beginPath();
        parcelFeature.geometry.coordinates
          .flat(parcelFeature.geometry.type === 'MultiPolygon' ? 2 : 1)
          .map((lngLat) => mapRef.current.project(lngLat))
          .forEach(({ x: parcelX, y: parcelY }) =>
            canvasCtx.lineTo(canvasWidth + parcelX, canvasHeight + parcelY),
          );

        canvasCtx.fill();
      }
    }
  }, [
    mapRef,
    parcelId,
    allLayerGroups.parcelPolygons,
    parcelsByMtrsaId,
    canvasCtx,
    canvasWidth,
    canvasHeight,
  ]);

  const overlaySource = useMemo(
    () =>
      mapRef.current && canvasWidth && canvasHeight
        ? {
            type: 'canvas',
            canvas: canvasRef.current,
            coordinates: [
              mapRef.current.unproject([-canvasWidth, -canvasHeight]),
              mapRef.current.unproject([2 * canvasWidth, -canvasHeight]),
              mapRef.current.unproject([2 * canvasWidth, 2 * canvasHeight]),
              mapRef.current.unproject([-canvasWidth, 2 * canvasHeight]),
            ],
            animate: true,
          }
        : null,
    [canvasHeight, canvasWidth, mapRef],
  );

  const overlayLayer = useMemo(
    () =>
      parcelId
        ? {
            id: OVERLAY_LAYER_ID,
            type: 'raster',
            source: OVERLAY_SOURCE_ID,
          }
        : null,
    [parcelId],
  );

  return [overlaySource, overlayLayer];
}

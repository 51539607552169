import { useEffect } from 'react';
import { node, func } from 'prop-types';

import useMDXPageContext, { MDXPageContextProvider } from './MDXPageContext';
import useParsePath from './useParsePath';
import useSingle from './useSingle';
import Consumer from './Consumer';

export default function MDXPage({
  getSingleId,
  getSingleData,
  remainMountedChildren,
  children,
}) {
  const { page, setPage } = useMDXPageContext();
  const { route, itemType, singleSlug } = useParsePath();
  const { singleId, singleData } = useSingle(
    singleSlug,
    getSingleId,
    getSingleData,
  );

  useEffect(() => {
    setPage({
      children,
      remainMountedChildren,
      route,
      itemType,
      singleId,
      singleData,
    });

    return () => setPage(null);
  }, [
    children,
    itemType,
    remainMountedChildren,
    route,
    setPage,
    singleData,
    singleId,
    singleSlug,
  ]);

  return page ? page.children : null;
}

MDXPage.propTypes = {
  getSingleId: func,
  getSingleData: func,
  children: node.isRequired,
};

MDXPage.defaultProps = {
  getSingleId: null,
  getSingleData: null,
};

export { MDXPageContextProvider, useMDXPageContext, Consumer };

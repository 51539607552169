/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSelectedStates, ALL_STATES } from '../../features/selectedStates';
import styles from './index.module.css';
import Link from '../Link';

export default function ResetButton() {
  const selectedStates = useSelector((state) => state.selectedStates);
  const dispatch = useDispatch();
  const resetToAllUS = (event) => {
    event.preventDefault();
    dispatch(setSelectedStates(ALL_STATES));
  };

  return (
    <div
      className={
        selectedStates === ALL_STATES
          ? styles.resetButton
          : styles.resetButtonVisible
      }
    >
      <Link className={styles.button} onClick={resetToAllUS}>
        Reset to all U.S.
      </Link>
    </div>
  );
}

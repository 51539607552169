import React, { useMemo, useRef, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from '@reach/router';
import Img from 'gatsby-image';
import c from 'classnames';
import { arrayOf, shape, string } from 'prop-types';
import throttle from 'lodash.throttle';

import { provideData } from '../data';
import Section from './Section';
import Link from './Link';
import ToggleSlider from './ToggleSlider';
import lguLogoSrc from '../../content/img/lgu-logo-header-b-on-w-62px.svg';
import { setAdjustForInflation } from '../features/adjustForInflation';

import facebookIconSrc from '../../content/img/facebook-icon.svg';
import facebookIconDesktopSrc from '../../content/img/facebook-icon-desktop.svg';
import twitterIconSrc from '../../content/img/twitter-icon.svg';
import twitterIconDesktopSrc from '../../content/img/twitter-icon-desktop.svg';
import emailIconSrc from '../../content/img/email-icon.svg';
import emailIconDesktopSrc from '../../content/img/email-icon-desktop.svg';
import styles from './FloatingNavbar.module.css';

const UPDATE_FREQUENCY = 250;

function useHCNLogo() {
  const {
    file: {
      childImageSharp: { fixed: hcnLogoFixed },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "img/hcn-logo.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return <Img fixed={hcnLogoFixed} alt="High Country News" />;
}

function useShareLinks() {
  const { href } = useLocation();
  return useMemo(
    () => [
      {
        href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          href,
        )}`,
        iconSrc: facebookIconSrc,
        iconDesktopSrc: facebookIconDesktopSrc,
        iconAlt: 'Share with Facebook',
      },

      {
        href: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          href,
        )}`,
        iconSrc: twitterIconSrc,
        iconDesktopSrc: twitterIconDesktopSrc,
        iconAlt: 'Share with Twitter',
      },

      {
        href: `mailto:?subject=${encodeURIComponent(
          'Land Grab Universities',
        )}&body=${encodeURIComponent(href)}`,
        iconSrc: emailIconSrc,
        iconDesktopSrc: emailIconDesktopSrc,
        iconAlt: 'Share with Email',
      },
    ],
    [href],
  );
}

function FloatingNavbar({ anchorLinks }) {
  const adjustForInflation = useSelector((state) => state.adjustForInflation);
  const shareLinks = useShareLinks();
  const hcnLogoImg = useHCNLogo();
  const dispatch = useDispatch();
  const elementRef = useRef();
  const [visible, setVisible] = useState(false);

  const handleToggleChange = (value) => {
    dispatch(setAdjustForInflation(value));
  };

  useEffect(() => {
    const handleScroll = throttle(() => {
      setVisible(elementRef.current.getBoundingClientRect().top < 1);
    }, UPDATE_FREQUENCY);

    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className={c('sticky-top', styles.FloatingNavbar, {
        [styles.visible]: visible,
      })}
      ref={elementRef}
    >
      <Section
        desktopFigureWidth
        className={styles.bar}
        colProps={{ className: styles.barInner }}
      >
        {/* HCN logo */}
        <Link href="https://www.hcn.org" className={styles.hcnLogo}>
          {hcnLogoImg}
        </Link>

        {/* LGU logo */}
        <Link href="/">
          <img
            src={lguLogoSrc}
            alt="Land Grab Universities"
            className={styles.lguLogo}
          />
        </Link>

        <div className={styles.navLinks}>
          {/* Primary nav links */}
          <nav className={styles.primaryLinks}>
            <Link href="/" activeClassName={styles.active}>
              Overview
            </Link>
            <Link href="/universities" activeClassName={styles.active}>
              Universities
            </Link>
            <Link href="/tribes" activeClassName={styles.active}>
              Nations
            </Link>
            <Link href="/lands" activeClassName={styles.active}>
              Lands
            </Link>
            <Link
              href="#stories"
              activeClassName={styles.active}
              className="d-none d-desktop-inline"
            >
              Stories
            </Link>
          </nav>

          {/* Page anchor links */}
          <nav id="subnav" className={c('d-none active', styles.anchorLinks)}>
            {anchorLinks.map(({ label, href }) => (
              <Link
                key={href}
                href={href}
                className="subnav-link hidden"
                activeClassName={styles.active}
              >
                {label}
              </Link>
            ))}
          </nav>
        </div>

        {/* Share links */}
        <div className={styles.shareLinks}>
          {shareLinks.map(({ href, iconSrc, iconDesktopSrc, iconAlt }) => (
            <Link
              key={href}
              href={href}
              target="_share"
              rel="noopener noreferrer"
            >
              <img className="d-desktop-none" src={iconSrc} alt={iconAlt} />
              <img
                className="d-none d-desktop-inline"
                src={iconDesktopSrc}
                alt={iconAlt}
              />
            </Link>
          ))}
        </div>
      </Section>

      {/* Currency display toggle */}
      <div className={styles.currencyTab}>
        <div className={styles.InflationToggle}>
          <strong className="d-desktop-none">Currency Display</strong>
          <strong className="d-none d-desktop-inline">Currency</strong>
          <ToggleSlider
            falseLabel="Original value"
            trueLabel="Adjusted for inflation"
            value={adjustForInflation}
            onChange={handleToggleChange}
          />
        </div>
      </div>
    </div>
  );
}

FloatingNavbar.propTypes = {
  anchorLinks: arrayOf(
    shape({ label: string.isRequired, href: string.isRequired }),
  ).isRequired,
};

export default provideData(FloatingNavbar, ['inflationFactors']);

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useMDXPageContext } from '../MDXPage';
import useData from '../../data';
import useMapboxConfig from './useMapboxConfig';

export default function useRenderedParcels() {
  const { allLayerGroups } = useMapboxConfig();
  const { mapRef } = useMDXPageContext();
  const { bounds, mapLoaded } = useSelector((state) => state.mapboxMap);
  const { parcelsByMtrsaId } = useData();
  return useMemo(() => {
    if (mapRef.current && mapLoaded) {
      const { layers, key } = allLayerGroups.parcelPolygons;
      return [
        ...new Set(
          mapRef.current
            .queryRenderedFeatures(
              [
                mapRef.current.project(bounds[0]),
                mapRef.current.project(bounds[1]),
              ],
              { layers },
            )
            .map(
              (feature) =>
                parcelsByMtrsaId[feature.properties[key.mapboxFeatureProperty]],
            )
            .filter((a) => a),
        ),
      ];
    }

    return [];
  }, [
    mapRef,
    mapLoaded,
    allLayerGroups.parcelPolygons,
    bounds,
    parcelsByMtrsaId,
  ]);
}

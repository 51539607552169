import { useMemo } from 'react';
import { ArcLayer } from '@deck.gl/layers';
import { MapboxLayer } from '@deck.gl/mapbox';
import { useSelector } from 'react-redux';

import useData from '../../data';
import useMatchingFeatures from './useMatchingFeatures';

export const ARC_LAYER_ID = 'arc-layer';

export default function useArcLayer() {
  const {
    universities: matchingUniversities,
    parcelPoints: matchingParcels,
  } = useMatchingFeatures();

  const {
    universityCentroidCoordinatesById,
    universitiesByState,
    parcelCentroidCoordinatesById,
    parcelGrantStateById,
  } = useData();

  const visibleUniversityCentroidCoordinatesById = useMemo(
    () =>
      Object.assign(
        {},
        ...(
          matchingUniversities || [
            ...Object.keys(universityCentroidCoordinatesById),
          ]
        ).map((university) => ({
          [university]: universityCentroidCoordinatesById[university],
        })),
      ),
    [matchingUniversities, universityCentroidCoordinatesById],
  );

  const arcs = useMemo(
    () =>
      (matchingParcels || [...Object.keys(parcelCentroidCoordinatesById)])
        .filter((parcel) => parcelCentroidCoordinatesById[parcel])
        .map((parcel) => {
          const grantState = parcelGrantStateById[parcel];
          const grantUniversities = universitiesByState[grantState];
          return (grantUniversities || [])
            .filter(
              (university) =>
                visibleUniversityCentroidCoordinatesById[university],
            )
            .map((university) => ({
              sourcePosition: parcelCentroidCoordinatesById[parcel],
              targetPosition:
                visibleUniversityCentroidCoordinatesById[university],
              parcelId: parcel,
            }));
        })
        .flat(),
    [
      matchingParcels,
      parcelCentroidCoordinatesById,
      parcelGrantStateById,
      universitiesByState,
      visibleUniversityCentroidCoordinatesById,
    ],
  );

  const { arcsVisible } = useSelector((state) => state.mapboxMap);
  return useMemo(
    () =>
      new MapboxLayer({
        id: ARC_LAYER_ID,
        type: ArcLayer,
        data: arcs,
        widthUnits: 'meters',
        widthScale: Math.min(25000 / (arcs.length || 1) ** (1 / 2), 2500),
        getSourceColor: [117, 222, 227, 150],
        getTargetColor: [239, 93, 40, 150],
        visible: arcsVisible,
      }),
    [arcs, arcsVisible],
  );
}

import React, { useMemo, useCallback } from 'react';
import { number, bool, arrayOf, string, shape, objectOf } from 'prop-types';

import Link from '../Link';
import useData, { provideData } from '../../data';
import BaseTable from './BaseTable';
import { LinkCell, DecimalCell, CurrencyCell, MultipleCell } from './Cell';
import useAdjustForInflation from '../../features/adjustForInflation/useAdjustForInflation';

import styles from './index.module.css';

function useTableData({ states, grantStates, university }) {
  const {
    tribeAllFieldsById,
    parcelsByState,
    universityAllFieldsById,
    parcelsByGrantState,
    parcelTransferById,
    parcelPriceSoldById,
    parcelsByTribe,
    parcelAreaById,
    paidToTribesByParcel,
    raisedByUniversitiesByParcel,
  } = useData();

  const {
    state: universityState,
    grantShare: universityGrantShare,
    grantReceivedYear: universityGrantReceivedYear,
  } = useMemo(() => (university && universityAllFieldsById[university]) || {}, [
    university,
    universityAllFieldsById,
  ]);

  const statesParcels = useMemo(
    () =>
      states &&
      new Set(
        states
          .map((state) => parcelsByState[state])
          .filter((a) => a)
          .flat(),
      ),
    [parcelsByState, states],
  );

  const statesTransfers = useMemo(
    () =>
      statesParcels &&
      new Set(
        [...statesParcels]
          .map((parcel) => parcelTransferById[parcel])
          .filter((a) => a),
      ),
    [parcelTransferById, statesParcels],
  );

  const grantStatesParcels = useMemo(
    () =>
      grantStates &&
      new Set(
        grantStates
          .map((state) => parcelsByState[state])
          .filter((a) => a)
          .flat(),
      ),
    [grantStates, parcelsByState],
  );

  const grantStatesTransfers = useMemo(
    () =>
      grantStatesParcels &&
      new Set(
        [...grantStatesParcels]
          .map((parcel) => parcelTransferById[parcel])
          .filter((a) => a),
      ),
    [grantStatesParcels, parcelTransferById],
  );

  const universityParcels = useMemo(
    () => university && new Set(parcelsByGrantState[universityState]),
    [parcelsByGrantState, university, universityState],
  );

  const universityTransfers = useMemo(
    () =>
      universityParcels &&
      new Set(
        [...universityParcels]
          .map((parcel) => parcelTransferById[parcel])
          .filter((a) => a),
      ),
    [parcelTransferById, universityParcels],
  );

  const adjustForInflation = useAdjustForInflation(); // dep: inflationFactors
  const getRaisedByUniversitiesForParcel = useCallback(
    university
      ? (parcel) =>
          adjustForInflation(
            parcelPriceSoldById[parcel] * universityGrantShare,
            universityGrantReceivedYear,
          )
      : (parcel) => raisedByUniversitiesByParcel[parcel],
    [
      adjustForInflation,
      parcelPriceSoldById,
      universityGrantReceivedYear,
      universityGrantShare,
    ],
  );

  return useMemo(
    () =>
      Object.entries(tribeAllFieldsById)
        .map(([tribe, { slug, name, alternateNames }]) => {
          const parcels = parcelsByTribe[tribe].filter(
            (parcel) =>
              (!universityParcels || universityParcels.has(parcel)) &&
              (!statesParcels || statesParcels.has(parcel)) &&
              (!grantStatesParcels || grantStatesParcels.has(parcel)),
          );

          if (!parcels.length) {
            return null;
          }

          const transfers = tribeAllFieldsById[tribe].transfers.filter(
            (transfer) =>
              (!universityTransfers || universityTransfers.has(transfer)) &&
              (!statesTransfers || statesTransfers.has(transfer)) &&
              (!grantStatesTransfers || grantStatesTransfers.has(transfer)),
          );

          const parcelsArea = parcels
            .map((parcel) => parcelAreaById[parcel])
            .filter((a) => a)
            .reduce((a, b) => a + b, 0);

          const paidToTribes =
            parcels
              .map((parcel) => paidToTribesByParcel[parcel])
              .filter((a) => a)
              .reduce((a, b) => a + b, 0) * (universityGrantShare || 1);

          const raisedByUniversities = parcels
            .map((parcel) => getRaisedByUniversitiesForParcel(parcel))
            .filter((a) => a)
            .reduce((a, b) => a + b, 0);

          return {
            href: `/tribes/${slug}`,
            tribe: name,
            alternateNames,
            treaties: transfers.length,
            acresCeded: parcelsArea,
            payment: paidToTribes,
            uniEarned: raisedByUniversities,
            multiple: raisedByUniversities / paidToTribes,
          };
        })
        .filter((a) => a),
    [
      getRaisedByUniversitiesForParcel,
      grantStatesParcels,
      grantStatesTransfers,
      paidToTribesByParcel,
      parcelAreaById,
      parcelsByTribe,
      statesParcels,
      statesTransfers,
      tribeAllFieldsById,
      universityGrantShare,
      universityParcels,
      universityTransfers,
    ],
  );
}

const columns = [
  {
    Header: '',
    id: 'i',
    disableSortBy: true,
  },
  {
    Header: 'Tribal Nation',
    accessor: 'tribe',
    Cell: LinkCell,
  },
  {
    Header: 'Alternate Names',
    accessor: 'alternateNames',
  },
  {
    Header: 'Cessions',
    accessor: 'treaties',
    hideOnMobile: true,
  },
  {
    Header: 'Acres',
    accessor: 'acresCeded',
    hideOnMobile: true,
    Cell: DecimalCell,
  },
  {
    Header: 'U.S. Paid',
    accessor: 'payment',
    hideOnMobile: true,
    Cell: CurrencyCell,
  },
  {
    Header: 'Univ. Raised',
    accessor: 'uniEarned',
    Cell: CurrencyCell,
  },
  {
    Header: 'Return',
    accessor: 'multiple',
    hideOnMobile: true,
    Cell: MultipleCell,
  },
];

const goToPageButton = (
  <Link className={styles.moreButton} href="/tribes">
    Go to Tribal Nations page
  </Link>
);

function TribesTable({
  dataParams,
  title,
  initialSortBy,
  pageSize,
  isOverview,
}) {
  const data = useTableData(dataParams);
  return (
    <BaseTable
      title={title}
      searchBy={['tribe', 'alternateNames']}
      searchPlaceholder="Search land cessions by tribal nation"
      columns={columns}
      initialSortBy={initialSortBy}
      hiddenColumns={['alternateNames']}
      pageSize={pageSize}
      moreButton={isOverview ? goToPageButton : null}
      data={data}
    />
  );
}

TribesTable.propTypes = {
  dataParams: shape({
    states: arrayOf(string),
    grantStates: arrayOf(string),
    university: string,
  }),
  title: string,
  initialSortBy: objectOf(shape({ desc: bool.isRequired })),
  pageSize: number.isRequired,
  isOverview: bool,
};

TribesTable.defaultProps = {
  dataParams: {},
  title: 'Tribal nations making land cessions',
  initialSortBy: { uniEarned: { desc: true } },
  isOverview: false,
};

export default provideData(TribesTable, [
  'tribeAllFieldsById',
  'parcelsByState',
  'universityAllFieldsById',
  'parcelsByGrantState',
  'parcelTransferById',
  'parcelPriceSoldById',
  'parcelsByTribe',
  'parcelAreaById',
  'paidToTribesByParcel',
  'raisedByUniversitiesByParcel',
  'inflationFactors',
]);

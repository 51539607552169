import '../../content/page-wrapper/styles.scss';
import '../styles/bootstrap.scss';
import '../styles/global.css';

import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

import { useMDXPageContext } from './MDXPage';
import headerHTML from '../../content/page-wrapper/header.html';
import footerHTML from '../../content/page-wrapper/footer.html';

const MIN_WIDTH = 600;
const UPDATE_FREQUENCY = 250;

export default function PageWrapper({ children }) {
  const { page } = useMDXPageContext();
  useLayoutEffect(() => {
    const metaViewportElement = document.querySelector('meta[name="viewport"]');
    const originalContent = metaViewportElement.content;
    const handleResize = throttle(() => {
      if (window.screen.width < MIN_WIDTH) {
        metaViewportElement.content = `width=${MIN_WIDTH}`;
      } else {
        metaViewportElement.content = originalContent;
      }
    }, UPDATE_FREQUENCY);

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      metaViewportElement.content = originalContent;
    };
  }, []);

  return (
    <>
      <a name="top" />
      <section dangerouslySetInnerHTML={{ __html: headerHTML }} />
      <main>
        {page ? page.remainMountedChildren : null}
        {children}
      </main>
      <section dangerouslySetInnerHTML={{ __html: footerHTML }} />
    </>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

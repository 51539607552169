import React from 'react';
import { string } from 'prop-types';

import useData, { provideData } from '../../data';
import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import TribesTable from './TribesTable';

function TribesForSelectedStatesForUniversityTable({ university, ...props }) {
  const selectedStates = useSelectedStates(); // dep: stateAllFieldsById, statesByGrantType
  const { universityAllFieldsById } = useData();
  const { name: universityName } = universityAllFieldsById[university];
  return (
    <TribesTable
      dataParams={{ states: selectedStates, university }}
      title={`Tribal land benefiting ${universityName}`}
      {...props}
    />
  );
}

TribesForSelectedStatesForUniversityTable.propTypes = {
  university: string.isRequired,
};

export default provideData(TribesForSelectedStatesForUniversityTable, [
  'stateAllFieldsById',
  'statesByGrantType',
  'universityAllFieldsById',
]);

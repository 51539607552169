import React, { useMemo } from 'react';

import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import StateDropdown from '../StateDropdown';
import { Currency } from '../Formatted';
import useData, { provideData } from '../../data';

import styles from './index.module.css';

function FilterPhrase() {
  const { universitiesByState, raisedByUniversitiesByUniversity } = useData();
  const selectedStates = useSelectedStates();

  const { nUniversities, raisedByUniversities } = useMemo(() => {
    const universitiesInSelectedStates = selectedStates
      .map((state) => universitiesByState[state])
      .flat();

    const raisedByUniversitiesByStates = universitiesInSelectedStates
      .filter((university) => raisedByUniversitiesByUniversity[university])
      .reduce(
        (acc, university) => acc + raisedByUniversitiesByUniversity[university],
        0,
      );

    return {
      nUniversities: universitiesInSelectedStates.length,
      raisedByUniversities: raisedByUniversitiesByStates,
    };
  }, [raisedByUniversitiesByUniversity, selectedStates, universitiesByState]);

  return (
    <div className={styles.FilterPhrase}>
      <span>
        Indigenous land <span> raised </span>
        <strong>
          <Currency>{raisedByUniversities}</Currency>
        </strong>{' '}
        for <strong>{nUniversities}</strong>{' '}
        {` universit${nUniversities === 1 ? 'y' : 'ies'} in `}
      </span>
      <StateDropdown />
    </div>
  );
}

export default provideData(FilterPhrase, [
  'stateAllFieldsById',
  'statesByGrantType',
  'universitiesByState',
  'raisedByUniversitiesByUniversity',
]);

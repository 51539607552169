import MDXPage from "../../../../src/components/MDXPage";
import PageMeta from "../../../../src/components/PageMeta";
import Section from "../../../../src/components/Section";
import Link from "../../../../src/components/Link";
import StaticNavbar from "../../../../src/components/StaticNavbar.mdx";
import * as React from 'react';
export default {
  MDXPage,
  PageMeta,
  Section,
  Link,
  StaticNavbar,
  React
};
import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useMDXPageContext } from '../MDXPage';

export default function useMapboxConfig() {
  const data = useStaticQuery(graphql`
    query {
      allMapViewYaml {
        edges {
          node {
            route
            defaultShowArcs
            defaultLayerGroups {
              name
            }
            style {
              name
            }
          }
        }
      }

      allMapLayerGroupYaml {
        edges {
          node {
            name
            layers
            key {
              appItemType
              mapboxFeatureProperty
            }
          }
        }
      }

      allMapStyleYaml {
        edges {
          node {
            name
            styleJSON
          }
        }
      }
    }
  `);

  const allLayerGroups = useMemo(
    () =>
      Object.assign(
        {},
        ...data.allMapLayerGroupYaml.edges.map(({ node }) => ({
          [node.name]: node,
        })),
      ),
    [data.allMapLayerGroupYaml.edges],
  );

  const allLayers = useMemo(
    () =>
      Object.assign(
        {},
        ...Object.values(allLayerGroups)
          .map(({ layers }) => layers.map((layer) => ({ [layer]: true })))
          .flat(),
      ),
    [allLayerGroups],
  );

  const allMapViews = useMemo(
    () =>
      Object.assign(
        {},
        ...data.allMapViewYaml.edges.map(
          ({ node: { route, defaultLayerGroups, ...node } }) => ({
            [route]: {
              route,
              defaultLayerGroups: defaultLayerGroups.map(({ name }) => name),
              baseStyle: data.allMapStyleYaml.edges.find(
                (mapStyleYaml) => mapStyleYaml.node.name === node.style.name,
              ).node.styleJSON,
              ...node,
            },
          }),
        ),
      ),
    [data.allMapStyleYaml.edges, data.allMapViewYaml.edges],
  );

  const keysByLayer = useMemo(
    () =>
      Object.assign(
        {},
        ...Object.values(allLayerGroups)
          .filter(({ key }) => key)
          .map(({ key, layers }) => layers.map((layer) => ({ [layer]: key })))
          .flat(),
      ),
    [allLayerGroups],
  );

  const { page } = useMDXPageContext();
  return useMemo(
    () =>
      allMapViews[page.route]
        ? {
            ...allMapViews[page.route],
            allLayerGroups,
            allLayers,
            keysByLayer,
          }
        : null,
    [allLayerGroups, allLayers, allMapViews, keysByLayer, page.route],
  );
}

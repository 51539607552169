import React, { useMemo } from 'react';
import { string } from 'prop-types';

import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import StateDropdown from '../StateDropdown';
import { Currency } from '../Formatted';
import useData, { provideData } from '../../data';

import styles from './index.module.css';

function FilterPhrase({ tribe }) {
  const {
    tribeAllFieldsById,
    parcelsByState,
    parcelsByTribe,
    parcelGrantStateById,
    universitiesByState,
    raisedByUniversitiesByParcel,
  } = useData();

  const selectedStates = useSelectedStates();
  const tribeName = tribeAllFieldsById[tribe].name;
  const [nUniversities, raisedByUniversities] = useMemo(() => {
    const parcelsForSelectedStates = new Set(
      selectedStates
        .map((state) => parcelsByState[state])
        .filter((a) => a)
        .flat(),
    );

    const parcelsForTribeInSelectedStates = parcelsByTribe[
      tribe
    ].filter((parcel) => parcelsForSelectedStates.has(parcel));

    const universitiesReceivingParcels = [
      ...new Set(
        parcelsForTribeInSelectedStates
          .map((parcel) => parcelGrantStateById[parcel])
          .filter((a) => a),
      ),
    ]
      .map((state) => universitiesByState[state])
      .filter((a) => a)
      .flat();

    const raisedByUniversitiesForSelectedStates = parcelsForTribeInSelectedStates
      .map((parcel) => raisedByUniversitiesByParcel[parcel])
      .filter((a) => a)
      .reduce((a, b) => a + b, 0);

    return [
      universitiesReceivingParcels.length,
      raisedByUniversitiesForSelectedStates,
    ];
  }, [
    parcelGrantStateById,
    parcelsByState,
    parcelsByTribe,
    raisedByUniversitiesByParcel,
    selectedStates,
    tribe,
    universitiesByState,
  ]);

  return (
    <div className={styles.FilterPhrase}>
      <strong>{tribeName}</strong>
      <span> land in </span>
      <StateDropdown />
      <span> raised </span>
      <strong>
        <Currency>{raisedByUniversities}</Currency>
      </strong>
      <span> for </span>
      <strong>{nUniversities}</strong>
      <span>{` universit${nUniversities === 1 ? 'y' : 'ies'}`}</span>
    </div>
  );
}

FilterPhrase.propTypes = { tribe: string.isRequired };

export default provideData(FilterPhrase, [
  'stateAllFieldsById',
  'statesByGrantType',
  'tribeAllFieldsById',
  'parcelsByState',
  'parcelsByTribe',
  'parcelGrantStateById',
  'universitiesByState',
  'raisedByUniversitiesByParcel',
]);

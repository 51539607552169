import { createSlice } from '@reduxjs/toolkit';

const adjustforInflationSlice = createSlice({
  name: 'adjustforInflation',
  initialState: false,
  reducers: {
    setAdjustForInflation(state, action) {
      return action.payload;
    },
  },
});

export const { setAdjustForInflation } = adjustforInflationSlice.actions;
export default adjustforInflationSlice.reducer;

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const MAX_BOUNDS = [
  [-158, 5],
  [-38, 80],
];

export const DEFAULT_BOUNDS = [
  [-138, 25],
  [-58, 60],
];

export const DEFAULT_PITCH = 40;

const setLayerGroupAndLayersVisible = (state, name, visible) => {
  state.layerGroupVisible[name] = visible;
  state.allLayerGroups[name].layers.forEach((layer) => {
    state.layerVisible[layer] = visible;
  });
};

const setArcDependencies = (state) => {
  if (!state.defaultLayerGroupVisible.parcelPoints) {
    setLayerGroupAndLayersVisible(state, 'parcelPoints', state.arcsVisible);
  }

  if (!state.defaultLayerGroupVisible.universities) {
    setLayerGroupAndLayersVisible(state, 'universities', state.arcsVisible);
  }
};

const mapboxMapSlice = createSlice({
  name: 'mapboxMap',
  initialState: {
    route: null,
    defaultLayerGroupVisible: {},
    allLayerGroups: {},
    layerGroupVisible: {},
    layerVisible: {},
    arcsVisible: false,
    bounds: DEFAULT_BOUNDS,
    pitch: DEFAULT_PITCH,
    loaded: false,
  },

  reducers: {
    setMapView(
      state,
      {
        payload: { route, allLayerGroups, defaultLayerGroups, defaultShowArcs },
      },
    ) {
      state.route = route;
      state.defaultLayerGroupVisible = Object.assign(
        {},
        ...defaultLayerGroups.map((name) => ({ [name]: true })),
      );

      state.allLayerGroups = allLayerGroups;
      state.layerGroupVisible = {};
      state.layerVisible = {};
      defaultLayerGroups.forEach((name) =>
        setLayerGroupAndLayersVisible(state, name, true),
      );

      state.arcsVisible = defaultShowArcs;
      setArcDependencies(state);
    },

    setLayerGroupVisible(state, { payload: layerGroupVisible }) {
      for (const [name, visible] of Object.entries(layerGroupVisible)) {
        setLayerGroupAndLayersVisible(state, name, visible);
      }
    },

    setArcsVisible(state, { payload: visible }) {
      state.arcsVisible = visible;
      setArcDependencies(state);
    },

    updateViewport(
      state,
      { payload: { bounds = state.bounds, pitch = state.pitch } },
    ) {
      state.bounds = bounds;
      state.pitch = pitch;
    },

    setMapLoaded(state, { payload: loaded }) {
      state.mapLoaded = loaded;
    },
  },
});

export const {
  setMapView,
  setLayerGroupVisible,
  setArcsVisible,
  updateViewport,
  setMapLoaded,
} = mapboxMapSlice.actions;

export default mapboxMapSlice.reducer;

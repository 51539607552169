import { combineReducers } from 'redux';

import adjustForInflation from './adjustForInflation';
import selectedStates from './selectedStates';
import mapboxMap from './mapboxMap';

export default combineReducers({
  adjustForInflation,
  selectedStates,
  mapboxMap,
});

import React, { useMemo } from 'react';
import { string } from 'prop-types';

import useData, { provideData } from '../../data';
import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import StateDropdown from '../StateDropdown';
import { Currency } from '../Formatted';
import useAdjustForInflation from '../../features/adjustForInflation/useAdjustForInflation';
import styles from './index.module.css';

function FilterPhrase({ university }) {
  const adjustForInflation = useAdjustForInflation();
  const {
    universityAllFieldsById,
    parcelsByGrantState,
    parcelsByState,
    parcelPriceSoldById,
  } = useData();

  const selectedStates = useSelectedStates();
  const {
    name: universityName,
    state: universityState,
    grantShare,
    grantReceivedYear,
  } = universityAllFieldsById[university];

  const universityRaised = useMemo(() => {
    const parcelsGrantedToUniversity = new Set(
      parcelsByGrantState[universityState],
    );

    let universityRaisedInSelectedGrantStates = 0;
    for (const state of selectedStates) {
      const parcelsForState = parcelsByState[state];
      if (parcelsForState) {
        for (const parcel of parcelsForState) {
          if (
            parcelsGrantedToUniversity.has(parcel) &&
            parcelPriceSoldById[parcel]
          ) {
            universityRaisedInSelectedGrantStates += adjustForInflation(
              parcelPriceSoldById[parcel] * grantShare,
              grantReceivedYear,
            );
          }
        }
      }
    }

    return universityRaisedInSelectedGrantStates;
  }, [
    adjustForInflation,
    grantReceivedYear,
    grantShare,
    parcelPriceSoldById,
    parcelsByGrantState,
    parcelsByState,
    selectedStates,
    universityState,
  ]);

  return (
    <div className={styles.FilterPhrase}>
      Indigenous land in <StateDropdown /> <span> raised </span>{' '}
      <strong>
        {' '}
        <Currency>{universityRaised}</Currency>{' '}
      </strong>{' '}
      <span>
        {' '}
        for <strong>{universityName}</strong>{' '}
      </span>
    </div>
  );
}

FilterPhrase.propTypes = {
  university: string.isRequired,
};

export default provideData(FilterPhrase, [
  'stateAllFieldsById',
  'statesByGrantType',
  'universityAllFieldsById',
  'parcelsByGrantState',
  'parcelsByState',
  'parcelPriceSoldById',
  'inflationFactors',
]);

import { useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setMapLoaded } from '../../features/mapboxMap';
import useMapboxConfig from './useMapboxConfig';
import useLayerFilters from './useLayerFilters';
import useArcLayer, { ARC_LAYER_ID } from './useArcLayer';
import useParcelOverlay, {
  OVERLAY_LAYER_ID,
  OVERLAY_SOURCE_ID,
} from './useParcelOverlay';

import { useMDXPageContext } from '../MDXPage';

export default function useMapboxStyle() {
  //  Get current map view parameters
  const { baseStyle, allLayers } = useMapboxConfig();
  let style = baseStyle;

  //  Set layer visibility
  const layerVisible = useSelector((state) => state.mapboxMap.layerVisible);
  style = useMemo(
    () => ({
      ...style,
      layers: style.layers
        .filter(({ id }) => allLayers[id])
        .map((layer) => ({
          ...layer,
          layout: {
            ...layer.layout,
            visibility: layerVisible[layer.id] ? 'visible' : 'none',
          },
        })),
    }),
    [style, layerVisible, allLayers],
  );

  //  Set layer filters
  const layerFilters = useLayerFilters();
  style = useMemo(
    () => ({
      ...style,
      layers: style.layers.map((layer) =>
        layerFilters[layer.id]
          ? {
              ...layer,
              filter: layerFilters[layer.id],
            }
          : layer,
      ),
    }),
    [layerFilters, style],
  );

  //  Get additional sources/layers for the map
  const [overlaySource, overlayLayer] = useParcelOverlay();
  const arcLayer = useArcLayer();

  //  Replace map style and update programmatically-defined layers
  const { mapLoaded } = useSelector((state) => state.mapboxMap);
  const dispatch = useDispatch();
  const { mapRef } = useMDXPageContext();
  const currentInvocation = useRef(0);
  useEffect(() => {
    const updateStyle = async (invocation) => {
      mapRef.current.setStyle(style);
      while (!mapRef.current.isStyleLoaded()) {
        await new Promise((resolve) => setTimeout(resolve, 250));
        if (invocation !== currentInvocation.current) {
          return;
        }
      }

      if (overlaySource) {
        if (!mapRef.current.getSource(OVERLAY_SOURCE_ID)) {
          mapRef.current.addSource(OVERLAY_SOURCE_ID, overlaySource);
        } else {
          mapRef.current
            .getSource(OVERLAY_SOURCE_ID)
            .setCoordinates(overlaySource.coordinates);
        }
      }

      if (!overlayLayer && mapRef.current.getLayer(OVERLAY_LAYER_ID)) {
        mapRef.current.removeLayer(OVERLAY_SOURCE_ID);
      }

      if (
        overlaySource &&
        overlayLayer &&
        !mapRef.current.getLayer(OVERLAY_LAYER_ID)
      ) {
        mapRef.current.addLayer(overlayLayer);
      }

      if (mapRef.current.getLayer(ARC_LAYER_ID)) {
        mapRef.current.removeLayer(ARC_LAYER_ID);
      }

      mapRef.current.addLayer(arcLayer);
      while (!mapRef.current.isStyleLoaded()) {
        await new Promise((resolve) => setTimeout(resolve, 250));
        if (invocation !== currentInvocation.current) {
          return;
        }
      }

      dispatch(setMapLoaded(true));
    };

    if (mapLoaded) {
      dispatch(setMapLoaded(false));
      updateStyle(++currentInvocation.current).catch((e) => {
        throw e;
      });
    }
  }, [
    arcLayer,
    dispatch,
    mapLoaded,
    mapRef,
    overlayLayer,
    overlaySource,
    style,
  ]);
}

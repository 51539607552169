import React from 'react';
import { string } from 'prop-types';

import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import useData, { provideData } from '../../data';
import UniversitiesTable from './UniversitiesTable';

function UniversitiesForSelectedStatesForTribeTable({ tribe, ...props }) {
  const selectedStates = useSelectedStates(); // dep: stateAllFieldsById, statesByGrantType
  const { tribeAllFieldsById } = useData();
  const { name: tribeName } = tribeAllFieldsById[tribe];
  return (
    <UniversitiesTable
      dataParams={{ parcelStates: selectedStates, tribe }}
      title={`Universities benefiting from ${tribeName} land grants`}
      {...props}
    />
  );
}

UniversitiesForSelectedStatesForTribeTable.propTypes = {
  tribe: string.isRequired,
};

export default provideData(UniversitiesForSelectedStatesForTribeTable, [
  'stateAllFieldsById',
  'statesByGrantType',
  'tribeAllFieldsById',
]);

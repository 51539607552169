import React, { useMemo } from 'react';
import { string } from 'prop-types';

import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import StateDropdown from '../StateDropdown';
import { Decimal } from '../Formatted';
import useData, { provideData } from '../../data';

import styles from './index.module.css';

function FilterPhrase({ tribe }) {
  const {
    tribeAllFieldsById,
    parcelsByState,
    parcelsByTribe,
    parcelAreaById,
  } = useData();

  const selectedStates = useSelectedStates();
  const tribeName = tribeAllFieldsById[tribe].name;

  const [nParcels, parcelsArea] = useMemo(() => {
    const parcelsInSelectedStates = new Set(
      selectedStates
        .map((state) => parcelsByState[state])
        .filter((a) => a)
        .flat(),
    );

    const parcelsInSelectedStatesForTribe = parcelsByTribe[
      tribe
    ].filter((parcel) => parcelsInSelectedStates.has(parcel));

    const parcelsInSelectedStatesForTribeArea = parcelsInSelectedStatesForTribe
      .map((parcel) => parcelAreaById[parcel])
      .filter((a) => a)
      .reduce((a, b) => a + b, 0);

    return [
      parcelsInSelectedStatesForTribe.length,
      parcelsInSelectedStatesForTribeArea,
    ];
  }, [parcelAreaById, parcelsByState, parcelsByTribe, selectedStates, tribe]);

  return (
    <div className={styles.FilterPhrase}>
      <strong>{tribeName}</strong>
      <span> cessions supplied </span>
      <strong>
        <Decimal>{nParcels}</Decimal>
      </strong>
      <span> Morrill Act parcels in </span>
      <StateDropdown />
      <span> totaling </span>
      <strong>
        <Decimal>{parcelsArea}</Decimal>
      </strong>
      <span> acres </span>
    </div>
  );
}

FilterPhrase.propTypes = { tribe: string.isRequired };

export default provideData(FilterPhrase, [
  'stateAllFieldsById',
  'statesByGrantType',
  'tribeAllFieldsById',
  'parcelsByState',
  'parcelsByTribe',
  'parcelAreaById',
]);

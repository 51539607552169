import { useMemo } from 'react';

import useData from '../../data';
import useMatchingFeatures from './useMatchingFeatures';
import useMapboxConfig from './useMapboxConfig';

export default function useLayerFilters() {
  const { allLayerGroups } = useMapboxConfig();
  const matchingFeaturesByLayerGroup = useMatchingFeatures();
  const {
    universityNameById,
    transferRoyceNumberById,
    parcelMtrsaIdById,
  } = useData();

  const mapboxPropertyValueByItemIdByItemType = useMemo(
    () => ({
      university: universityNameById,
      transfer: transferRoyceNumberById,
      parcel: parcelMtrsaIdById,
    }),
    [parcelMtrsaIdById, transferRoyceNumberById, universityNameById],
  );

  return useMemo(
    () =>
      Object.assign(
        {},
        ...Object.entries(matchingFeaturesByLayerGroup)
          .map(([layerGroup, matchingFeatures]) => {
            const { key, layers } = allLayerGroups[layerGroup];
            const filter = [
              'any',
              ['!', ['has', key.mapboxFeatureProperty]],
              [
                'in',
                ['get', key.mapboxFeatureProperty],
                [
                  'literal',
                  matchingFeatures.map(
                    (id) =>
                      mapboxPropertyValueByItemIdByItemType[key.appItemType][
                        id
                      ],
                  ),
                ],
              ],
            ];

            return layers.map((layer) => ({
              [layer]: filter,
            }));
          })
          .flat(),
      ),
    [
      allLayerGroups,
      mapboxPropertyValueByItemIdByItemType,
      matchingFeaturesByLayerGroup,
    ],
  );
}

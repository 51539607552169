import React, { useMemo } from 'react';

import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import { Decimal } from '../Formatted';
import useData, { provideData } from '../../data';

import styles from './index.module.css';

function FilterPhrase() {
  const { tribesByParcelState, parcelAreaByState } = useData();
  const selectedStates = useSelectedStates();

  const nTribesForSelectedParcelStates = useMemo(
    () =>
      new Set(
        selectedStates
          .map((state) => tribesByParcelState[state])
          .filter((a) => a)
          .flat(),
      ).size,
    [selectedStates, tribesByParcelState],
  );

  const parcelAreaForSelectedStates = useMemo(
    () =>
      selectedStates
        .map((state) => parcelAreaByState[state])
        .filter((a) => a)
        .reduce((a, b) => a + b, 0),
    [parcelAreaByState, selectedStates],
  );

  return (
    <div className={styles.FilterPhrase}>
      <strong>{nTribesForSelectedParcelStates}</strong>
      <span>
        {` tribal nation${
          nTribesForSelectedParcelStates === 1 ? '' : 's'
        } ceded `}
      </span>
      <strong>
        <Decimal>{parcelAreaForSelectedStates}</Decimal>
      </strong>
      <span> acres of land </span>
      <span> benefiting universities</span>
    </div>
  );
}

export default provideData(FilterPhrase, [
  'stateAllFieldsById',
  'statesByGrantType',
  'tribesByParcelState',
  'parcelAreaByState',
]);

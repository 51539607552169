import { useMemo } from 'react';

import { ROUTES } from '../../constants';
import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import useData from '../../data';
import { useMDXPageContext } from '../MDXPage';

const getFeaturesByLayerGroupByRoute = {
  [ROUTES.overview.path]: {
    universities: ({ selectedStates }, { universitiesByState }) =>
      selectedStates
        .filter((state) => universitiesByState[state])
        .map((state) => universitiesByState[state])
        .flat(),

    parcelPoints: ({ selectedStates }, { parcelsByGrantState }) =>
      selectedStates
        .filter((state) => parcelsByGrantState[state])
        .map((state) => parcelsByGrantState[state])
        .flat(),

    parcelPolygons: ({ selectedStates }, { parcelsByGrantState }) =>
      selectedStates
        .filter((state) => parcelsByGrantState[state])
        .map((state) => parcelsByGrantState[state])
        .flat(),

    parcelMeta: ({ selectedStates }, { parcelsByGrantState }) =>
      selectedStates
        .filter((state) => parcelsByGrantState[state])
        .map((state) => parcelsByGrantState[state])
        .flat(),
  },

  [ROUTES.universityArchive.path]: {
    universities: ({ selectedStates }, { universitiesByState }) =>
      selectedStates
        .filter((state) => universitiesByState[state])
        .map((state) => universitiesByState[state])
        .flat(),

    parcelPoints: ({ selectedStates }, { parcelsByGrantState }) =>
      selectedStates
        .filter((state) => parcelsByGrantState[state])
        .map((state) => parcelsByGrantState[state])
        .flat(),

    parcelPolygons: ({ selectedStates }, { parcelsByGrantState }) =>
      selectedStates
        .filter((state) => parcelsByGrantState[state])
        .map((state) => parcelsByGrantState[state])
        .flat(),

    parcelMeta: ({ selectedStates }, { parcelsByGrantState }) =>
      selectedStates
        .filter((state) => parcelsByGrantState[state])
        .map((state) => parcelsByGrantState[state])
        .flat(),
  },

  [ROUTES.universitySingle.path]: {
    universities: ({ singleId }) => [singleId],
    parcelPoints: (
      { singleId },
      { parcelsByGrantState, universityAllFieldsById },
    ) => parcelsByGrantState[universityAllFieldsById[singleId].state] || [],

    parcelPolygons: (
      { singleId },
      { parcelsByGrantState, universityAllFieldsById },
    ) => parcelsByGrantState[universityAllFieldsById[singleId].state] || [],

    parcelMeta: (
      { singleId },
      { parcelsByGrantState, universityAllFieldsById },
    ) => parcelsByGrantState[universityAllFieldsById[singleId].state] || [],
  },

  [ROUTES.tribeArchive.path]: {
    universities: (
      { selectedStates },
      { parcelsByState, parcelGrantStateById, universitiesByState },
    ) =>
      [
        ...new Set(
          selectedStates
            .map((state) => parcelsByState[state])
            .filter((a) => a)
            .flat()
            .map((parcel) => parcelGrantStateById[parcel]),
        ),
      ]
        .map((grantState) => universitiesByState[grantState])
        .filter((a) => a)
        .flat(),

    parcelPoints: ({ selectedStates }, { parcelsByState }) =>
      selectedStates.map((state) => parcelsByState[state]).flat(),

    parcelPolygons: ({ selectedStates }, { parcelsByState }) =>
      selectedStates.map((state) => parcelsByState[state]).flat(),

    parcelMeta: ({ selectedStates }, { parcelsByState }) =>
      selectedStates.map((state) => parcelsByState[state]).flat(),
  },

  [ROUTES.tribeSingle.path]: {
    universities: (
      { singleId },
      { parcelsByTribe, parcelGrantStateById, universitiesByState },
    ) =>
      [
        ...new Set(
          parcelsByTribe[singleId] &&
            parcelsByTribe[singleId].map(
              (parcel) => parcelGrantStateById[parcel],
            ),
        ),
      ]
        .map((grantState) => universitiesByState[grantState])
        .filter((a) => a)
        .flat(),

    transfers: ({ singleId }, { tribeAllFieldsById }) =>
      tribeAllFieldsById[singleId].transfers,

    parcelPoints: ({ singleId }, { tribeAllFieldsById, parcelsByTransfer }) =>
      tribeAllFieldsById[singleId].transfers
        .map((transfer) => parcelsByTransfer[transfer])
        .flat(),

    parcelPolygons: ({ singleId }, { tribeAllFieldsById, parcelsByTransfer }) =>
      tribeAllFieldsById[singleId].transfers
        .map((transfer) => parcelsByTransfer[transfer])
        .flat(),

    parcelMeta: ({ singleId }, { tribeAllFieldsById, parcelsByTransfer }) =>
      tribeAllFieldsById[singleId].transfers
        .map((transfer) => parcelsByTransfer[transfer])
        .flat(),
  },

  [ROUTES.landArchive.path]: {
    universities: (
      { selectedStates },
      { parcelsByState, parcelGrantStateById, universitiesByState },
    ) =>
      [
        ...new Set(
          selectedStates
            .map((state) => parcelsByState[state])
            .filter((a) => a)
            .flat()
            .map((parcel) => parcelGrantStateById[parcel]),
        ),
      ]
        .map((grantState) => universitiesByState[grantState])
        .filter((a) => a)
        .flat(),

    parcelPoints: ({ selectedStates }, { parcelsByState }) =>
      selectedStates.map((state) => parcelsByState[state]).flat(),

    parcelPolygons: ({ selectedStates }, { parcelsByState }) =>
      selectedStates.map((state) => parcelsByState[state]).flat(),

    parcelMeta: ({ selectedStates }, { parcelsByState }) =>
      selectedStates.map((state) => parcelsByState[state]).flat(),
  },

  [ROUTES.landSingle.path]: {
    universities: (
      { singleId },
      { universitiesByState, parcelGrantStateById },
    ) => universitiesByState[parcelGrantStateById[singleId]],

    transfers: ({ singleId }, { parcelTransferById }) => [
      parcelTransferById[singleId],
    ],

    parcelPoints: ({ singleId }) => [singleId],
  },
};

export default function useMatchingFeatures() {
  const selectedStates = useSelectedStates();
  const data = useData();
  const {
    page: { route, singleId },
  } = useMDXPageContext();

  return useMemo(() => {
    const matchingFeaturesByLayerGroup = {};
    const getFeaturesByLayerGroup = getFeaturesByLayerGroupByRoute[route];
    for (const [layerGroup, getFeatures] of Object.entries(
      getFeaturesByLayerGroup,
    )) {
      const context = { selectedStates, singleId };
      matchingFeaturesByLayerGroup[layerGroup] = [
        ...new Set((getFeatures(context, data) || []).filter((v) => v)),
      ];
    }

    return matchingFeaturesByLayerGroup;
  }, [data, route, selectedStates, singleId]);
}

import React, { Fragment } from 'react';
import { shape, bool } from 'prop-types';
import c from 'classnames';

import useData, { provideData } from '../../../data';
import useMapboxConfig from '../useMapboxConfig';
import Link from '../../Link';
import { ITEM_TYPES } from '../../../constants';
import styles from './index.module.css';

function ParcelPopupContent({ popupFeature, clicked }) {
  const { keysByLayer } = useMapboxConfig();
  const {
    parcelsByMtrsaId,
    parcelSlugById,
    parcelMtrsaIdById,
    parcelCountyById,
    parcelStateById,
    stateAllFieldsById,
    parcelAreaById,
    tribesByParcel,
    tribeAllFieldsById,
    parcelTransferById,
    transferAllFieldsById,
    parcelGrantStateById,
  } = useData();
  const { mapboxFeatureProperty: featureProperty } = keysByLayer[
    popupFeature.layer.id
  ];

  const parcelName = popupFeature.properties[featureProperty];
  const parcel = parcelsByMtrsaId[parcelName];
  const mtrsaId = parcelMtrsaIdById[parcel];
  const county = parcelCountyById[parcel];
  const stateAbbreviation =
    parcelStateById[parcel] &&
    stateAllFieldsById[parcelStateById[parcel]].abbreviation;

  const area = parcelAreaById[parcel];
  const tribes = tribesByParcel[parcel]
    ? tribesByParcel[parcel].map((tribe) => tribeAllFieldsById[tribe])
    : [];

  const transferType =
    parcelTransferById[parcel] &&
    transferAllFieldsById[parcelTransferById[parcel]].type;

  const grantStateName =
    parcelGrantStateById[parcel] &&
    stateAllFieldsById[parcelGrantStateById[parcel]].name;

  const tableRows = [
    ['ID', mtrsaId],
    ['Acres', area],
    [
      'Tribal nations',
      tribes.map(({ name, slug }, i) => (
        <Fragment key={slug}>
          <Link href={`/${ITEM_TYPES.tribe.slug}/${slug}`}>{name}</Link>
          {i === tribes.length - 1 ? '' : ', '}
        </Fragment>
      )),
    ],
    ['U.S. Acquired', transferType],
  ];

  const parcelSlug = parcelSlugById[parcel];
  const parcelUrl = `/${ITEM_TYPES.parcel.slug}/${parcelSlug}`;

  return (
    <div className={c(styles.MapPopup, !clicked && styles.collapsed)}>
      <div className={styles.popupTitle}>
        <span>Parcel in </span>
        {county}
        <span> County, </span>
        {stateAbbreviation}
        <span> granted to </span>
        {grantStateName}
      </div>
      {clicked ? (
        <>
          <table>
            <tbody>
              {tableRows.map(([label, value]) => (
                <tr key={label}>
                  <td className={styles.headerColumn}>{label}</td>
                  <td className={styles.bodyColumn}>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Link
            href={parcelUrl}
            className={c('btn btn-link', styles.moreButton)}
          >
            See Land Parcel Page
          </Link>
        </>
      ) : (
        <div className={styles.clickForDetails}>Click for details</div>
      )}
    </div>
  );
}

ParcelPopupContent.propTypes = {
  popupFeature: shape({ properties: shape({}).isRequired }).isRequired,
  clicked: bool.isRequired,
};

export default provideData(ParcelPopupContent, [
  'parcelsByMtrsaId',
  'parcelSlugById',
  'parcelMtrsaIdById',
  'parcelCountyById',
  'parcelStateById',
  'stateAllFieldsById',
  'parcelAreaById',
  'tribesByParcel',
  'tribeAllFieldsById',
  'parcelTransferById',
  'transferAllFieldsById',
  'parcelGrantStateById',
]);

import React, { useMemo } from 'react';
import { string } from 'prop-types';

import useData, { provideData } from '../../data';
import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import StateDropdown from '../StateDropdown';
import { Decimal } from '../Formatted';
import styles from './index.module.css';

function FilterPhrase({ university }) {
  const {
    universityAllFieldsById,
    parcelsByGrantState,
    parcelsByState,
    parcelAreaById,
  } = useData();

  const selectedStates = useSelectedStates();
  const {
    name: universityName,
    state: universityState,
  } = universityAllFieldsById[university];

  const [nParcels, parcelsArea] = useMemo(() => {
    const parcelsGrantedToUniversity = new Set(
      parcelsByGrantState[universityState],
    );

    const parcelsInSelectedStates = selectedStates
      .map((state) => parcelsByState[state])
      .filter((a) => a)
      .flat()
      .filter((parcel) => parcelsGrantedToUniversity.has(parcel));

    const parcelsInSelectedStatesArea = parcelsInSelectedStates
      .map((parcel) => parcelAreaById[parcel])
      .filter((a) => a)
      .reduce((a, b) => a + b, 0);

    return [parcelsInSelectedStates.length, parcelsInSelectedStatesArea];
  }, [
    parcelAreaById,
    parcelsByGrantState,
    parcelsByState,
    selectedStates,
    universityState,
  ]);

  return (
    <div className={styles.FilterPhrase}>
      <strong>{universityName}</strong>
      <span> benefited from </span>
      <strong>
        <Decimal>{nParcels}</Decimal>
      </strong>
      <span> Indigenous land parcels in </span>
      <StateDropdown />
      <span> totaling </span>
      <strong>
        <Decimal>{parcelsArea}</Decimal>
      </strong>
      <span> acres.</span>
    </div>
  );
}

FilterPhrase.propTypes = {
  university: string.isRequired,
};

export default provideData(FilterPhrase, [
  'stateAllFieldsById',
  'statesByGrantType',
  'universityAllFieldsById',
  'parcelsByGrantState',
  'parcelsByState',
  'parcelAreaById',
]);

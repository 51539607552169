import { useMemo } from 'react';

import useMDXPageContext from './MDXPageContext';

export default function Consumer({ render }) {
  const {
    page: { singleData },
  } = useMDXPageContext();

  return useMemo(() => (singleData ? render(singleData) : null), [
    render,
    singleData,
  ]);
}

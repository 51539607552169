import React from 'react';
import { string } from 'prop-types';

import useData, { provideData } from '../../data';
import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import LandsTable from './LandsTable';

function LandsForSelectedStatesForTribeTable({ tribe, ...props }) {
  const selectedStates = useSelectedStates(); // dep: stateAllFieldsById, statesByGrantType
  const { tribeAllFieldsById } = useData();
  const { name: tribeName } = tribeAllFieldsById[tribe];
  return (
    <LandsTable
      dataParams={{ states: selectedStates, tribe }}
      title={`Indigenous land ceded in ${tribeName} cessions`}
      {...props}
    />
  );
}

LandsForSelectedStatesForTribeTable.propTypes = {
  tribe: string.isRequired,
};

export default provideData(LandsForSelectedStatesForTribeTable, [
  'stateAllFieldsById',
  'statesByGrantType',
  'tribeAllFieldsById',
]);

import React, { useMemo } from 'react';

import useData, { provideData } from '../../data';
import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import StateDropdown from '../StateDropdown';
import { Decimal } from '../Formatted';

import styles from './index.module.css';

function FilterPhrase() {
  const { parcelsByState } = useData();
  const selectedStates = useSelectedStates();
  const nParcels = useMemo(
    () =>
      selectedStates
        .map((state) => parcelsByState[state])
        .filter((a) => a)
        .flat().length,
    [parcelsByState, selectedStates],
  );

  return (
    <div className={styles.FilterPhrase}>
      <strong>
        <Decimal>{nParcels}</Decimal>
      </strong>
      {' Morrill Act land grant parcel'}
      {nParcels === 1 ? '' : 's'}
      {` ${nParcels === 1 ? 'was' : 'were'} located in `}
      <StateDropdown />
    </div>
  );
}

export default provideData(FilterPhrase, [
  'stateAllFieldsById',
  'statesByGrantType',
  'parcelsByState',
]);

import { useMatch } from '@reach/router';

import { ITEM_TYPES, ROUTES } from '../../constants';

const itemTypesBySlug = Object.assign(
  {},
  ...Object.values(ITEM_TYPES).map((itemType) => ({
    [itemType.slug]: itemType,
  })),
);

function useRouteMatch() {
  let route = null;
  for (const { path } of Object.values(ROUTES)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const match = useMatch(path);
    if (match && !route) {
      route = path;
    }
  }

  return route;
}

export default function useParsePath() {
  const match = useMatch('/:itemTypeSlug/*singleSlug');
  const route = useRouteMatch();
  if (match) {
    const { itemTypeSlug, singleSlug } = match;
    if (itemTypesBySlug[itemTypeSlug]) {
      if (singleSlug) {
        return {
          route,
          itemType: itemTypesBySlug[itemTypeSlug],
          singleSlug,
        };
      }

      return {
        route,
        itemType: itemTypesBySlug[itemTypeSlug],
      };
    }
  }

  return { route };
}

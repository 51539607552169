import React, { useMemo } from 'react';

import useData, { provideData } from '../../data';
import { Decimal } from '../Formatted';
import styles from './index.module.css';
import { useRenderedParcels } from '../MapboxMap';

function FilterPhrase() {
  const { parcelAreaById, parcelGrantStateById } = useData();
  const renderedParcels = useRenderedParcels();
  const [nParcels, parcelsArea, nGrantStates] = useMemo(() => {
    const renderedParcelsArea = renderedParcels
      .map((parcel) => parcelAreaById[parcel])
      .filter((a) => a)
      .reduce((a, b) => a + b, 0);

    const grantStates = new Set(
      renderedParcels
        .map((parcel) => parcelGrantStateById[parcel])
        .filter((a) => a),
    );

    return [renderedParcels.length, renderedParcelsArea, grantStates.size];
  }, [parcelAreaById, parcelGrantStateById, renderedParcels]);

  return (
    <div className={styles.FilterPhrase}>
      <span>{` There ${nParcels === 1 ? 'is' : 'are'} `}</span>
      <strong>
        <Decimal>{nParcels}</Decimal>
      </strong>
      <span> Indigenous land parcels in </span>
      <strong> the current map view. </strong>
      <span>{` ${nParcels === 1 ? 'It contains' : 'They contain'} `}</span>
      <strong>
        <Decimal>{parcelsArea}</Decimal>
      </strong>
      <span> acres granted to universities in </span>
      <strong>
        <Decimal>{nGrantStates}</Decimal>
      </strong>
      <span>{` ${nGrantStates === 1 ? 'state' : 'states'}`}</span>
    </div>
  );
}

export default provideData(FilterPhrase, [
  'parcelAreaById',
  'parcelGrantStateById',
  'parcelsByMtrsaId',
]);

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const ALL_STATES = 'All States';
export const LAND_STATES = 'Land';
export const SCRIP_STATES = 'Scrip';
const NEW_YORK = 'NY'; // stateId of New York

const selectedStatesSlice = createSlice({
  name: 'selectedStates',
  initialState: NEW_YORK,
  reducers: {
    setSelectedStates(state, action) {
      return action.payload;
    },
  },
});

export const { setSelectedStates } = selectedStatesSlice.actions;
export default selectedStatesSlice.reducer;

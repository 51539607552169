import React from 'react';

import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import { provideData } from '../../data';
import UniversitiesTable from './UniversitiesTable';

function UniversitiesForSelectedGrantStatesTable(props) {
  const selectedStates = useSelectedStates(); // dep: stateAllFieldsById, statesByGrantType
  return (
    <UniversitiesTable dataParams={{ states: selectedStates }} {...props} />
  );
}

export default provideData(UniversitiesForSelectedGrantStatesTable, [
  'stateAllFieldsById',
  'statesByGrantType',
]);

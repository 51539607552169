import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useMDXPageContext } from '../../MDXPage';
import useMapboxConfig from '../useMapboxConfig';
import UniversityPopupContent from './UniversityPopupContent';
import TransferPopupContent from './TransferPopupContent';
import ParcelPopupContent from './ParcelPopupContent';
import Popup from './Popup';

const CONTENT_COMPONENTS = {
  university: UniversityPopupContent,
  transfer: TransferPopupContent,
  parcel: ParcelPopupContent,
};

const HOVER_DELAY = 500;

export default function useMapPopup() {
  const { mapRef } = useMDXPageContext();
  const mapLoaded = useSelector((state) => state.mapboxMap.mapLoaded);
  const { keysByLayer } = useMapboxConfig();
  const mouseLngLatRef = useRef(null);
  const [clickedFeature, setClickedFeature] = useState(null);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [hoverDelayElapsed, setHoverDelayElapsed] = useState(false);
  const [cursorPointer, setCursorPointer] = useState(false);
  const popupFeature = clickedFeature || (hoverDelayElapsed && hoveredFeature);

  const onMouseMove = useCallback(
    ({ point, lngLat }) => {
      mouseLngLatRef.current = lngLat;
      setHoverDelayElapsed(false);
      if (!clickedFeature) {
        setHoveredFeature(
          mapRef.current.queryRenderedFeatures(point, {
            layers: Object.keys(keysByLayer),
          })[0] || null,
        );
      }
    },
    [clickedFeature, keysByLayer, mapRef],
  );

  const onMouseLeave = useCallback(() => setHoveredFeature(null), []);

  const onClick = useCallback(
    ({ point }) => {
      if (!clickedFeature) {
        setClickedFeature(
          mapRef.current.queryRenderedFeatures(point, {
            layers: Object.keys(keysByLayer),
          })[0] || null,
        );
      }
    },
    [clickedFeature, keysByLayer, mapRef],
  );

  const onPopupClose = useCallback(() => {
    if (clickedFeature) {
      setClickedFeature(null);
    }
  }, [clickedFeature]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapLoaded) {
      map.on('mousemove', onMouseMove).on('click', onClick);
      const container = map.getContainer();
      container.addEventListener('mouseleave', onMouseLeave);
      return () => {
        map.off('mousemove', onMouseMove).off('click', onClick);
        container.removeEventListener('mouseleave', onMouseLeave);
      };
    }

    return undefined;
  }, [onMouseMove, onClick, onMouseLeave, mapRef, mapLoaded]);

  const hoveredFeatureRef = useRef(hoveredFeature);
  hoveredFeatureRef.current = hoveredFeature;
  useEffect(() => {
    if (hoveredFeature) {
      mapRef.current.setFeatureState(hoveredFeature, { hover: true });
      setCursorPointer(true);
      const timeoutId = setTimeout(() => {
        if (hoveredFeature === hoveredFeatureRef.current) {
          setHoverDelayElapsed(true);
        }
      }, HOVER_DELAY);

      return () => {
        clearTimeout(timeoutId);
        setCursorPointer(false);
        if (mapRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          mapRef.current.setFeatureState(hoveredFeature, { hover: false });
        }
      };
    }

    return undefined;
  }, [hoveredFeature, mapRef]);

  useEffect(() => {
    if (clickedFeature) {
      mapRef.current.setFeatureState(clickedFeature, { hover: true });
      return () => {
        if (mapRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          mapRef.current.setFeatureState(clickedFeature, { hover: false });
        }
      };
    }

    return undefined;
  }, [clickedFeature, mapRef]);

  let itemType;
  if (popupFeature) {
    const { appItemType } = keysByLayer[popupFeature.layer.id];
    itemType = appItemType;
  }

  const ContentComponent = CONTENT_COMPONENTS[itemType];
  const mapPopup = popupFeature ? (
    <Popup
      mouseLngLatRef={mouseLngLatRef}
      popupFeature={popupFeature}
      clicked={!!clickedFeature}
      onClose={onPopupClose}
    >
      <ContentComponent
        popupFeature={popupFeature}
        clicked={!!clickedFeature}
      />
    </Popup>
  ) : null;

  return { mapPopup, cursorPointer };
}

import React, {
  useEffect,
  useState,
  Suspense as ReactSuspense,
  createContext,
  useContext,
} from 'react';
import { node } from 'prop-types';

const SuspenseContext = createContext();

/*
 * Suspense apparently renders a suspended tree before it resumes,
 * hiding it with `display: none;`.  This tweaked version lets child
 * components use the `useSuspended` hook to know if they're in a
 * rendered-while-suspended tree.
 */

export default function Suspense({ fallback, children }) {
  const [suspended, setSuspended] = useState();
  const Fallback = (props) => {
    useEffect(() => {
      setSuspended(true);
      return () => setSuspended(false);
    }, []);

    return props.children;
  };

  return (
    <ReactSuspense fallback={<Fallback>{fallback}</Fallback>}>
      <SuspenseContext.Provider value={suspended}>
        {children}
      </SuspenseContext.Provider>
    </ReactSuspense>
  );
}

Suspense.propTypes = {
  fallback: node,
  children: node,
};

Suspense.defaultProps = {
  fallback: null,
  children: null,
};

export function useSuspended() {
  return useContext(SuspenseContext);
}

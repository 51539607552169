/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { useLocation } from '@reach/router';
import c from 'classnames';

export default function Link({ href, className, activeClassName, ...props }) {
  const { hash } = useLocation();

  if (href.startsWith('/')) {
    return (
      <GatsbyLink
        to={href}
        className={className}
        activeClassName={activeClassName}
        partiallyActive={href !== '/'}
        {...props}
      />
    );
  }

  return (
    <a
      href={href}
      className={c(className, href === hash && activeClassName)}
      {...props}
    />
  );
}

Link.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
};

Link.defaultProps = {
  href: '',
  className: null,
  activeClassName: null,
};

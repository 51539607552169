import React, { useMemo } from 'react';
import { number, bool, arrayOf, string, shape, objectOf } from 'prop-types';

import Link from '../Link';
import useData, { provideData } from '../../data';
import BaseTable from './BaseTable';
import { LinkCell, DecimalCell, CurrencyCell, MultipleCell } from './Cell';
import styles from './index.module.css';
import useAdjustForInflation from '../../features/adjustForInflation/useAdjustForInflation';

function useTableData({ states: statesArr, parcelStates, tribe }) {
  const {
    parcelsByState,
    parcelsByTribe,
    universityAllFieldsById,
    parcelsByGrantState,
    parcelAreaById,
    paidToTribesByParcel,
    parcelPriceSoldById,
  } = useData();

  const states = useMemo(() => statesArr && new Set(statesArr), [statesArr]);
  const parcelStatesParcels = useMemo(
    () =>
      parcelStates &&
      new Set(
        parcelStates
          .map((state) => parcelsByState[state])
          .filter((a) => a)
          .flat(),
      ),
    [parcelStates, parcelsByState],
  );

  const tribeParcels = useMemo(() => tribe && new Set(parcelsByTribe[tribe]), [
    parcelsByTribe,
    tribe,
  ]);

  const adjustForInflation = useAdjustForInflation(); // dep: inflationFactors
  return useMemo(
    () =>
      Object.values(universityAllFieldsById)
        .map(
          ({
            slug,
            name,
            state,
            stateAbbreviation,
            grantShare,
            grantReceivedYear,
          }) => {
            if (states && !states.has(state)) {
              return null;
            }

            const parcels = parcelsByGrantState[state].filter(
              (parcel) =>
                (!parcelStatesParcels || parcelStatesParcels.has(parcel)) &&
                (!tribeParcels || tribeParcels.has(parcel)),
            );

            if (!parcels.length) {
              return null;
            }

            const parcelsArea = parcels
              .map((parcel) => parcelAreaById[parcel])
              .filter((a) => a)
              .reduce((a, b) => a + b, 0);

            const paidToTribes =
              parcels
                .map((parcel) => paidToTribesByParcel[parcel])
                .filter((a) => a)
                .reduce((a, b) => a + b, 0) * grantShare;

            const raisedByUniversities = parcels
              .map((parcel) =>
                adjustForInflation(
                  parcelPriceSoldById[parcel] * grantShare,
                  grantReceivedYear,
                ),
              )
              .filter((a) => a)
              .reduce((a, b) => a + b, 0);

            return {
              href: `/universities/${slug}`,
              university: name,
              state: stateAbbreviation,
              acres: parcelsArea,
              payments: paidToTribes,
              earned: raisedByUniversities,
              multiple: raisedByUniversities / paidToTribes,
            };
          },
        )
        .filter((a) => a),
    [
      adjustForInflation,
      paidToTribesByParcel,
      parcelAreaById,
      parcelStatesParcels,
      parcelPriceSoldById,
      parcelsByGrantState,
      states,
      tribeParcels,
      universityAllFieldsById,
    ],
  );
}

const columns = [
  {
    Header: '',
    id: 'i',
    disableSortBy: true,
  },
  {
    Header: 'University',
    accessor: 'university',
    Cell: LinkCell,
  },
  {
    Header: 'State',
    accessor: 'state',
    hideOnMobile: true,
  },
  {
    Header: 'Acres',
    accessor: 'acres',
    Cell: DecimalCell,
  },
  {
    Header: 'U.S. Paid',
    accessor: 'payments',
    hideOnMobile: true,
    Cell: CurrencyCell,
  },
  {
    Header: 'Univ. Raised',
    accessor: 'earned',
    Cell: CurrencyCell,
  },
  {
    Header: 'Return',
    accessor: 'multiple',
    hideOnMobile: true,
    Cell: MultipleCell,
  },
];

const goToPageButton = (
  <Link className={styles.moreButton} href="/universities">
    Go to Universities page
  </Link>
);

function UniversitiesTable({
  dataParams,
  title,
  initialSortBy,
  pageSize,
  isOverview,
}) {
  const data = useTableData(dataParams);
  return (
    <BaseTable
      title={title}
      searchBy={['university']}
      searchPlaceholder="Search universities by name"
      columns={columns}
      initialSortBy={initialSortBy}
      pageSize={pageSize}
      moreButton={isOverview ? goToPageButton : null}
      data={data}
    />
  );
}

UniversitiesTable.propTypes = {
  dataParams: shape({
    states: arrayOf(string),
    grantStates: arrayOf(string),
    tribe: string,
  }),
  title: string,
  initialSortBy: objectOf(shape({ desc: bool.isRequired })),
  pageSize: number.isRequired,
  isOverview: bool,
};

UniversitiesTable.defaultProps = {
  dataParams: {},
  initialSortBy: { earned: { desc: true } },
  isOverview: false,
  title: 'Universities Benefiting from Land Grants',
};

export default provideData(UniversitiesTable, [
  'parcelsByState',
  'parcelsByTribe',
  'universityAllFieldsById',
  'parcelsByGrantState',
  'parcelAreaById',
  'paidToTribesByParcel',
  'parcelPriceSoldById',
  'inflationFactors',
]);

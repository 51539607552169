import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMDXPageContext } from '../MDXPage';
import {
  MAX_BOUNDS,
  DEFAULT_BOUNDS,
  DEFAULT_PITCH,
  setMapLoaded,
} from '../../features/mapboxMap';
import useMapboxConfig from './useMapboxConfig';

const LOADING = 'LOADING';
const LOADED = 'LOADED';

export default function useMapboxInstance() {
  const mapElementRef = useRef();
  const [mapboxImports, setMapboxImports] = useState();
  const { mapRef } = useMDXPageContext();
  const dispatch = useDispatch();
  const mapCreated = useSelector((state) => state.mapboxMap.mapCreated);
  const { baseStyle } = useMapboxConfig();
  const baseStyleRef = useRef(baseStyle);

  useEffect(() => {
    const loadMapboxImports = async () => {
      const { default: mapboxgl } = await import('mapbox-gl');
      const { default: MapboxGeocoder } = await import(
        '@mapbox/mapbox-gl-geocoder'
      );

      mapboxgl.GeocoderControl = MapboxGeocoder;
      window.mapboxgl = mapboxgl;
      setMapboxImports(LOADED);
    };

    if (!mapboxImports) {
      if (window.mapboxgl) {
        setMapboxImports(LOADED);
      } else {
        setMapboxImports(LOADING);
        loadMapboxImports();
      }
    }
  }, [mapboxImports]);

  useEffect(() => {
    if (mapboxImports === LOADED && !mapCreated) {
      window.mapboxgl.accessToken = process.env.GATSBY_MAPBOX_TOKEN;
      mapRef.current = new window.mapboxgl.Map({
        container: mapElementRef.current,
        attributionControl: false,
        scrollZoom: true,
        maxBounds: MAX_BOUNDS,
        bounds: DEFAULT_BOUNDS,
        pitch: DEFAULT_PITCH,
        style: baseStyleRef.current,
      });

      mapRef.current.once('load', () => {
        mapRef.current.resize();
        dispatch(setMapLoaded(true));
      });

      mapRef.current.addControl(
        new window.mapboxgl.NavigationControl({ visualizePitch: true }),
        'top-right',
      );

      mapRef.current.addControl(
        new window.mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        }),
        'top-right',
      );

      mapRef.current.addControl(
        new window.mapboxgl.GeocoderControl({
          accessToken: window.mapboxgl.accessToken,
          mapboxgl: window.mapboxgl,
          zoom: 13,
          placeholder: 'Search for a location',
        }),
        'bottom-right',
      );

      return () => {
        mapRef.current.remove();
        mapRef.current = null;
        dispatch(setMapLoaded(false));
      };
    }

    return undefined;
  }, [dispatch, mapCreated, mapRef, mapboxImports]);

  return mapElementRef;
}

import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setSelectedStates,
  ALL_STATES,
  LAND_STATES,
  SCRIP_STATES,
} from '../features/selectedStates';
import useData, { provideData } from '../data';

import styles from './StateDropdown.module.css';

const ALL_STATES_OPTION = { id: ALL_STATES, name: 'The United States' };
const LAND_STATES_OPTION = { id: LAND_STATES, name: 'Land States' };
const SCRIP_STATES_OPTION = { id: SCRIP_STATES, name: 'Scrip States' };

function StateDropdown() {
  const { stateAllFieldsById } = useData();
  const option = useSelector((state) => state.selectedStates);
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (event) => dispatch(setSelectedStates(event.target.value)),
    [dispatch],
  );

  const stateOptions = useMemo(
    () => [
      ALL_STATES_OPTION,
      LAND_STATES_OPTION,
      SCRIP_STATES_OPTION,
      ...Object.values(stateAllFieldsById).map(({ stateId, name }) => ({
        id: stateId,
        name,
      })),
    ],
    [stateAllFieldsById],
  );

  return (
    <div className={styles.StateDropdown}>
      <select
        className="custom-select custom-select-lg"
        value={option}
        onChange={handleChange}
      >
        {stateOptions.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default provideData(StateDropdown, ['stateAllFieldsById']);

import { FontAwesomeIcon as F } from '@fortawesome/react-fontawesome';
import { faCaretLeft as left, faCaretRight as right } from '@fortawesome/free-solid-svg-icons';
import c from 'classnames';
import { provideData } from "../../../../src/data";
import MDXPage, { Consumer } from "../../../../src/components/MDXPage";
import PageMeta from "../../../../src/components/PageMeta";
import Section from "../../../../src/components/Section";
import FloatingNavbar from "../../../../src/components/FloatingNavbar";
import MapboxMap from "../../../../src/components/MapboxMap";
import Link from "../../../../src/components/Link";
import Header from "../../../../src/components/Header.mdx";
import StaticNavbar from "../../../../src/components/StaticNavbar.mdx";
import { Percent, CurrencyWithCents, Decimal, Multiple, DateString } from "../../../../src/components/Formatted";
import styles from "../../../../src/templates/index.module.css";
import * as React from 'react';
export default {
  F,
  left,
  right,
  c,
  provideData,
  MDXPage,
  Consumer,
  PageMeta,
  Section,
  FloatingNavbar,
  MapboxMap,
  Link,
  Header,
  StaticNavbar,
  Percent,
  CurrencyWithCents,
  Decimal,
  Multiple,
  DateString,
  styles,
  React
};
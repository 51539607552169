import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Section from './Section';
import Link from './Link';
import styles from './StaticNavbar.module.css';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section className={styles.StaticNavbar} mdxType="Section">
  <nav className={styles.nav}>
    <Link href="/" activeClassName={styles.active} mdxType="Link">
      Overview
    </Link>
    <Link href="/universities" activeClassName={styles.active} mdxType="Link">
      Universities
    </Link>
    <Link href="/tribes" activeClassName={styles.active} mdxType="Link">
      Nations
    </Link>
    <Link href="/lands" activeClassName={styles.active} mdxType="Link">
      Lands
    </Link>
    <Link href="#stories" activeClassName={styles.active} className="d-none d-desktop-inline" mdxType="Link">
      Stories
    </Link>
  </nav>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
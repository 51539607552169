const decimalFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
});

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const currencyWithCentsFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const validateAndFormat = (value, formatter) => {
  if (typeof value !== 'number' || !Number.isFinite(value)) {
    return null;
  }

  return formatter.format(value);
};

export const Decimal = ({ children }) =>
  validateAndFormat(children, decimalFormatter) || '—';

export const Percent = ({ children }) =>
  validateAndFormat(children, percentFormatter) || '—';

export const Currency = ({ children }) =>
  validateAndFormat(children, currencyFormatter) || '—';

export const CurrencyWithCents = ({ children }) =>
  validateAndFormat(children, currencyWithCentsFormatter) || '—';

export const Multiple = ({ suffix = '', children }) =>
  validateAndFormat(children, {
    format(value) {
      if (value < 1) {
        return `<1${suffix}`;
      }

      return `${decimalFormatter.format(children)}${suffix}`;
    },
  }) || 'Incalc.';

export const DateString = ({ children, prefix, invalidDate = '–' }) => {
  if (!children || children === 'Invalid date') {
    return invalidDate;
  }

  return `${prefix || ''}${children}`;
};

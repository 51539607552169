/* eslint-disable no-param-reassign */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useData from '../../data';

import { ALL_STATES, LAND_STATES, SCRIP_STATES } from '.';

export default function useSelectedStates() {
  const option = useSelector((state) => state.selectedStates);
  const { statesByGrantType, stateAllFieldsById } = useData();

  return useMemo(() => {
    if (option === LAND_STATES || option === SCRIP_STATES) {
      return statesByGrantType[option] || [];
    }

    if (option === ALL_STATES) {
      return Object.keys(stateAllFieldsById);
    }

    return [option];
  }, [option, statesByGrantType, stateAllFieldsById]);
}

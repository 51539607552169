import React, { useMemo } from 'react';
import { string } from 'prop-types';

import useData, { provideData } from '../../data';
import BaseTable from './BaseTable';
import { MultiLinkCell, DateCell } from './Cell';
import styles from './index.module.css';

function useTableData(tribe) {
  const { tribeAllFieldsById, transferAllFieldsById } = useData();
  return useMemo(
    () =>
      tribeAllFieldsById[tribe].transfers.map((transfer) => {
        const { tribes, type, transferDate } = transferAllFieldsById[transfer];

        return {
          signatories: tribes.map((transferTribe) => ({
            href: `/tribes/${tribeAllFieldsById[transferTribe].slug}`,
            name: tribeAllFieldsById[transferTribe].name,
          })),
          transfer: type,
          date: transferDate,
        };
      }),
    [transferAllFieldsById, tribe, tribeAllFieldsById],
  );
}

const tableColumns = [
  {
    Header: '',
    id: 'i',
    disableSortBy: true,
  },
  {
    Header: 'Tribal Nation(s)',
    accessor: 'signatories',
    Cell: MultiLinkCell,
  },
  {
    Header: 'Transfer',
    accessor: 'transfer',
  },
  {
    Header: 'Date',
    accessor: 'date',
    Cell: DateCell,
  },
];

function TransfersForTribeTable({ tribe }) {
  const data = useTableData(tribe);
  const { tribeAllFieldsById } = useData();
  const { name: tribeName } = tribeAllFieldsById[tribe];
  return (
    <BaseTable
      title={`Treaties or seizures involving ${tribeName}`}
      searchBy={['signatories']}
      searchPlaceholder="Search treaties or seizures by tribe"
      columns={tableColumns}
      initialSortBy={{ signatories: { desc: false } }}
      pageSize={10}
      data={data}
      className={styles.transfersTable}
    />
  );
}

TransfersForTribeTable.propTypes = {
  tribe: string.isRequired,
};

export default provideData(TransfersForTribeTable, [
  'tribeAllFieldsById',
  'transferAllFieldsById',
]);

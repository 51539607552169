import React from 'react';

import { provideData } from '../../data';
import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import TribesTable from './TribesTable';

function TribesForSelectedStatesTable(props) {
  const selectedStates = useSelectedStates(); // dep: stateAllFieldsById, statesByGrantType
  return <TribesTable dataParams={{ states: selectedStates }} {...props} />;
}

export default provideData(TribesForSelectedStatesTable, [
  'stateAllFieldsById',
  'statesByGrantType',
]);

import React, { useMemo } from 'react';
import { string } from 'prop-types';

import { Decimal } from '../Formatted';
import useData, { provideData } from '../../data';

import styles from './index.module.css';

function FilterPhrase({ tribe }) {
  const { tribeAllFieldsById, parcelAreaByTribe } = useData();
  const [tribeName, nTransfers, parcelArea] = useMemo(() => {
    const { name, transfers } = tribeAllFieldsById[tribe];
    return [name, transfers.length, parcelAreaByTribe[tribe]];
  }, [parcelAreaByTribe, tribe, tribeAllFieldsById]);

  return (
    <div className={styles.FilterPhrase}>
      <strong>{tribeName}</strong>
      <span> made </span>
      <strong>
        <Decimal>{nTransfers}</Decimal>
      </strong>
      <span>
        {' '}
        land {` cession${nTransfers === 1 ? '' : 's'}  `} later supplying{' '}
      </span>
      <strong>
        <Decimal>{parcelArea}</Decimal>
      </strong>
      <span> Morrill Act grant acres.</span>
    </div>
  );
}

FilterPhrase.propTypes = { tribe: string.isRequired };

export default provideData(FilterPhrase, [
  'tribeAllFieldsById',
  'parcelAreaByTribe',
]);

import React, { useRef, useEffect } from 'react';
import { string, func } from 'prop-types';
import c from 'classnames';

import styles from './index.module.css';

export default function Search({
  searchPlaceholder,
  globalFilter,
  setGlobalFilter,
}) {
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <input
      ref={inputRef}
      className={c('form-control', styles.search)}
      type="search"
      placeholder={searchPlaceholder}
      value={globalFilter}
      onChange={(e) => setGlobalFilter(e.target.value || undefined)}
    />
  );
}

Search.propTypes = {
  searchPlaceholder: string.isRequired,
  globalFilter: string,
  setGlobalFilter: func.isRequired,
};

Search.defaultProps = {
  globalFilter: '',
};

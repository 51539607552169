import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { provideData } from '../../data';
import useMapboxInstance from './useMapboxInstance';
import useMapboxStyle from './useMapboxStyle';
import useMapboxViewState from './useMapboxViewState';
import LayerControls from './LayerControls';
import useRenderedParcels from './useRenderedParcels';
import useMapPopup from './useMapPopup';
import styles from './index.module.css';

function MapboxMap({ mapTitle, mapSubtitle }) {
  const mapElementRef = useMapboxInstance();
  const { mapPopup, cursorPointer } = useMapPopup();
  useMapboxStyle();
  useMapboxViewState();

  return (
    <div className={styles.MapboxMap}>
      <section className={c(styles.mapOverlay, 'container')}>
        <div
          className={c(
            styles.mapboxWrapper,
            cursorPointer && styles.cursorPointer,
          )}
          ref={mapElementRef}
        >
          {mapPopup}
        </div>
        <div className={styles.upper}>
          <div className={styles.title}>{mapTitle}</div>
          <div className={styles.subtitle}>{mapSubtitle}</div>
        </div>
        <LayerControls />
      </section>
    </div>
  );
}

MapboxMap.propTypes = {
  mapTitle: PropTypes.node,
  mapSubtitle: PropTypes.node,
};

MapboxMap.defaultProps = {
  mapTitle: null,
  mapSubtitle: null,
};

export default provideData(MapboxMap, [
  'stateAllFieldsById',
  'statesByGrantType',
  'universityAllFieldsById',
  'universityNameById',
  'universityCentroidCoordinatesById',
  'universitiesByName',
  'universitiesBySlug',
  'universitiesByState',
  'transferAllFieldsById',
  'transferRoyceNumberById',
  'transfersByRoyceNumber',
  'tribeAllFieldsById',
  'tribesBySlug',
  'parcelGrantStateById',
  'parcelTransferById',
  'parcelCentroidCoordinatesById',
  'parcelMtrsaIdById',
  'parcelsBySlug',
  'parcelsByMtrsaId',
  'parcelsByState',
  'parcelsByGrantState',
  'parcelsByTransfer',
  'parcelsByTribe',
]);

export { useRenderedParcels };

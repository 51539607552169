import React from 'react';
import { string } from 'prop-types';

import { provideData } from '../../data';
import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import LandsTable from './LandsTable';

function LandsForSelectedStatesForUniversityTable({ university, ...props }) {
  const selectedStates = useSelectedStates(); // dep: stateAllFieldsById, statesByGrantType
  return (
    <LandsTable
      dataParams={{ states: selectedStates, university }}
      {...props}
    />
  );
}

LandsForSelectedStatesForUniversityTable.propTypes = {
  university: string.isRequired,
};

export default provideData(LandsForSelectedStatesForUniversityTable, [
  'stateAllFieldsById',
  'statesByGrantType',
]);

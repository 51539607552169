import React from 'react';
import PropTypes, { bool } from 'prop-types';
import c from 'classnames';

/*
 * React component for vertical spans of the app.
 */

export default function Section({
  containerWidth,
  desktopContainerWidth,
  figureWidth,
  desktopFigureWidth,
  paragraphWidth,
  desktopParagraphWidth,
  containerProps,
  rowProps,
  colProps,
  children,
  ...props
}) {
  const colClassName =
    (paragraphWidth && 'col-10') ||
    (figureWidth && 'col-12') ||
    (containerWidth && 'col-12');

  const colDesktopClassName =
    (desktopParagraphWidth && 'col-desktop-7') ||
    (desktopFigureWidth && 'col-desktop-10') ||
    (desktopContainerWidth && 'col-desktop-12') ||
    (paragraphWidth && 'col-desktop-7') ||
    (figureWidth && 'col-desktop-10') ||
    (containerWidth && 'col-desktop-12');

  return (
    <section {...props}>
      <div
        {...containerProps}
        className={c('container', containerProps.className)}
      >
        <div
          {...rowProps}
          className={c('row justify-content-center', rowProps.className)}
        >
          <div
            {...colProps}
            className={c(
              'col',
              colClassName,
              colDesktopClassName,
              colProps.className,
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </section>
  );
}

Section.propTypes = {
  containerWidth: bool,
  desktopContainerWidth: bool,
  figureWidth: bool,
  desktopFigureWidth: bool,
  paragraphWidth: bool,
  desktopParagraphWidth: bool,
  containerProps: PropTypes.shape({ className: PropTypes.string }),
  rowProps: PropTypes.shape({ className: PropTypes.string }),
  colProps: PropTypes.shape({ className: PropTypes.string }),
  children: PropTypes.node,
};

Section.defaultProps = {
  containerWidth: true,
  desktopContainerWidth: false,
  figureWidth: false,
  desktopFigureWidth: false,
  paragraphWidth: false,
  desktopParagraphWidth: false,
  containerProps: {},
  rowProps: {},
  colProps: {},
  children: null,
};

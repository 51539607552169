import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Section from './Section';
import Link from './Link';
import styles from './Header.module.css';
import lguLogoSrc from '../../content/img/lgu-logo-header-b-on-w-62px.svg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section mdxType="Section">
  <div className={styles.Header}>
    <Link href="/" mdxType="Link">
      <img src={lguLogoSrc} alt="Land Grab Universities" className={styles.lguLogo} />
    </Link>
    <h5 className={styles.description}>
      How the United States funded land-grant universities with expropriated
      Indigenous land.
    </h5>
    <div className={styles.subtitleAuthors}>
      <h5 className={styles.subtitle}>
        <span>A </span>
        <em>High Country News</em>
        <span> Investigation</span>
      </h5>
      <h6 className={styles.authors}>
        By Robert Lee, Tristan Ahtone, Margaret Pearce, Kalen Goodluck, Geoff
        McGhee, Cody Leff, Katherine Lanpher and Taryn Salinas.
      </h6>
    </div>
  </div>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import { string } from 'prop-types';

const TableComponents = Object.assign(
  {},
  ...[
    'lands-for-map-view',
    'lands-for-selected-grant-states',
    'lands-for-selected-states-for-tribe',
    'lands-for-selected-states-for-university',
    'lands-for-selected-states',
    'transfers-for-tribe',
    'tribes-all',
    'tribes-for-selected-states-for-university',
    'tribes-for-selected-states',
    'universities-for-selected-grant-states',
    'universities-for-selected-states-for-tribe',
  ].map((name) => ({ [name]: require(`./table-${name}`).default })),
);

export default function Table({ name, ...rest }) {
  const TableComponent = TableComponents[name];
  return <TableComponent {...rest} />;
}

Table.propTypes = {
  name: string.isRequired,
};

import React, { useMemo, Fragment } from 'react';
import { shape, bool } from 'prop-types';
import c from 'classnames';

import useData, { provideData } from '../../../data';
import useMapboxConfig from '../useMapboxConfig';
import { Decimal, Currency, DateString } from '../../Formatted';
import styles from './index.module.css';
import Link from '../../Link';
import { ITEM_TYPES } from '../../../constants';

function TransferPopupContent({ popupFeature, clicked }) {
  const { keysByLayer } = useMapboxConfig();
  const {
    transfersByRoyceNumber,
    transferAllFieldsById,
    parcelsByTransfer,
    parcelAreaByTransfer,
    parcelGrantStateById,
    stateAllFieldsById,
    raisedByUniversitiesByTransfer,
    paidToTribesByTransfer,
    tribeAllFieldsById,
  } = useData();
  const { mapboxFeatureProperty: featureProperty } = keysByLayer[
    popupFeature.layer.id
  ];

  const transferName = popupFeature.properties[featureProperty];
  const transfer = transfersByRoyceNumber[transferName];
  const { type, transferDate, totalArea, tribes } = transferAllFieldsById[
    transfer
  ];

  const totalParcelArea = parcelAreaByTransfer[transfer];
  const grantStateAbbreviations = useMemo(() => {
    const abbreviations = [
      ...new Set(
        parcelsByTransfer[transfer]
          .filter((a) => a)
          .map((parcel) => parcelGrantStateById[parcel])
          .filter((a) => a),
      ),
    ].map((state) => stateAllFieldsById[state].abbreviation);

    abbreviations.sort();
    return abbreviations;
  }, [parcelGrantStateById, parcelsByTransfer, stateAllFieldsById, transfer]);

  const tribeFields = tribes.map((tribe) => tribeAllFieldsById[tribe]);
  const paid = paidToTribesByTransfer[transfer];
  const raised = raisedByUniversitiesByTransfer[transfer];
  const tableRows = [
    ['Total Acres', <Decimal>{totalArea}</Decimal>],
    ['Land grant acres', <Decimal>{totalParcelArea}</Decimal>],
    [
      'Tribal nations',
      tribeFields.map(({ name, slug }, i) => (
        <Fragment key={slug}>
          <Link href={`/${ITEM_TYPES.tribe.slug}/${slug}`}>{name}</Link>
          {i === tribes.length - 1 ? '' : ', '}
        </Fragment>
      )),
    ],
    ['Granted to', grantStateAbbreviations.join(', ')],
    ['U.S. paid', <Currency>{paid}</Currency>],
    ['Univ. raised', <Currency>{raised}</Currency>],
  ];

  return (
    <div className={c(styles.MapPopup, !clicked && styles.collapsed)}>
      <div className={styles.popupTitle}>
        <span>Land </span>
        {`${type.slice(0, 1).toLowerCase()}${type.slice(1)}`}
        <DateString prefix=", " invalidDate="">
          {transferDate}
        </DateString>
      </div>
      {clicked ? (
        <table>
          <tbody>
            {tableRows.map(([label, value]) => (
              <tr key={label}>
                <td className={styles.headerColumn}>{label}</td>
                <td className={styles.bodyColumn}>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className={styles.clickForDetails}>Click for details</div>
      )}
    </div>
  );
}

TransferPopupContent.propTypes = {
  popupFeature: shape({ properties: shape({}).isRequired }).isRequired,
  clicked: bool.isRequired,
};

export default provideData(TransferPopupContent, [
  'transfersByRoyceNumber',
  'transferAllFieldsById',
  'parcelsByTransfer',
  'parcelAreaByTransfer',
  'parcelGrantStateById',
  'stateAllFieldsById',
  'raisedByUniversitiesByTransfer',
  'paidToTribesByTransfer',
  'tribeAllFieldsById',
]);

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateViewport, setMapView } from '../../features/mapboxMap';
import { useMDXPageContext } from '../MDXPage';
import useMapboxConfig from './useMapboxConfig';

export default function useMapboxViewState() {
  const dispatch = useDispatch();
  const { mapRef } = useMDXPageContext();
  const mapView = useMapboxConfig();

  //  If route has changed, reset view
  useEffect(() => {
    dispatch(setMapView(mapView));
  }, [dispatch, mapView]);

  //  Update Redux view state on map move
  useEffect(() => {
    const map = mapRef.current;
    const handleMoveEnd = () =>
      dispatch(
        updateViewport({
          bounds: map.getBounds().toArray(),
          pitch: map.getPitch(),
        }),
      );

    if (map) {
      map.on('moveend', handleMoveEnd);
      return () => map.off('moveend', handleMoveEnd);
    }

    return undefined;
  }, [dispatch, mapRef]);
}

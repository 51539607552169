import { useMemo, useEffect } from 'react';
import { navigate } from '@reach/router';

import useData from '../../data';

export default function useSingle(singleSlug, getSingleId, getSingleData) {
  const data = useData();
  const singleId = useMemo(() => {
    if (singleSlug && getSingleId) {
      return getSingleId(singleSlug, data);
    }

    return null;
  }, [data, getSingleId, singleSlug]);

  const singleData = useMemo(() => {
    if (singleId && getSingleData) {
      return getSingleData(singleId, data);
    }

    return null;
  }, [data, getSingleData, singleId]);

  useEffect(() => {
    if (singleSlug && !singleId) {
      navigate('/404');
    }
  }, [singleId, singleSlug]);

  return { singleId, singleData };
}

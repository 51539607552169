import React from 'react';

import { provideData } from '../../data';
import useSelectedStates from '../../features/selectedStates/useSelectedStates';
import LandsTable from './LandsTable';

function LandsForSelectedGrantStatesTable(props) {
  const selectedStates = useSelectedStates(); // dep: stateAllFieldsById, statesByGrantType
  return <LandsTable dataParams={{ grantStates: selectedStates }} {...props} />;
}

export default provideData(LandsForSelectedGrantStatesTable, [
  'stateAllFieldsById',
  'statesByGrantType',
]);
